<div class="top-bar">
    <div class="top-bar__widget">
        <input type="text" [(ngModel)]="applicationName">
    </div>
    <div class="top-bar__viewport">
        <button class="btn btn__outline" (click)="addtoWebsite()">Zur Website hinzufügen</button>
    </div>
    <div class="top-bar__control">
        <button class="btn btn__transparent" (click)="cancel()">Abbrechen</button>
        <button class="btn btn__green btn__margin-right-15" (click)="save()">Speichern</button>
    </div>
</div>

<div class="main-app">
    <div class="main-app__sidebar">
        <div class="main-app__menu-container">
            <div>
                <button class="main-app__btn" [ngClass]="{'active':youtubeConfig.tabSwitch?.sources === true}"
                    (click)="switchTab('sources')">
                    <span class="material-icons">repeat</span>
                    <div class="main-app__btn-text">Quellen</div>
                </button>
                <button class="main-app__btn" [ngClass]="{'active':youtubeConfig.tabSwitch?.layout === true}"
                    (click)="switchTab('layout')">
                    <span class="material-icons">view_quilt</span>
                    <div class="main-app__btn-text">Layout</div>
                </button>
                <button class="main-app__btn" [ngClass]="{'active':youtubeConfig.tabSwitch?.design === true}"
                    (click)="switchTab('design')">
                    <span class="material-icons">color_lens</span>
                    <div class="main-app__btn-text">Design</div>
                </button>
                <button class="main-app__btn" [ngClass]="{'active':youtubeConfig.tabSwitch?.font === true}"
                    (click)="switchTab('font')">
                    <span class="material-icons">text_fields</span>
                    <div class="main-app__btn-text">Schrift</div>
                </button>
            </div>
        </div>
        <div class="main-app__menu-container-outer">
            <div class="main-app__outer-container">
                <div class="main-app__outer-container-inner" *ngIf="youtubeConfig.tabSwitch?.sources === true">
                    <div class="main-app__outer-title">
                        <h3>YouTube Config</h3>
                    </div>
                    <div class="main-app__outer-source-container">
                        <div class="main-app__outer-source-container-bg">
                            <h4>YouTube Channel-URL</h4>
                            <input class="main-app__input" type="text" (change)="changeUrl()" [(ngModel)]="youtubeApplication.youtube_url">
                            <h4 class="top-15">Google API-Schlüssel</h4>
                            <input class="main-app__input" type="text" (change)="changeKey()" [(ngModel)]="youtubeApplication.google_api">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-app__container">
        <ul class="row">
            <li class="col-md-3" *ngFor="let youtube of youtubePostsActive">
                <a href="https://www.youtube.com/watch?v={{youtube?.videoId}}" target="_blank">
                    <img [src]="youtube?.thumbnail" style="width: 100%;">
                </a>
            </li>
        </ul>
    </div>
</div>