<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Mein Zahlungen</h1>
    </div>
</div>
<div class="gray-content gray-content--small">
    <app-sidenav></app-sidenav>
</div>
<div class="white-content">
    <div class="container">
        <table *ngIf="!billingInfo" class="application-list">
            <thead class="application-list__header">
                <tr>
                    <th>Datum</th>
                    <th>Plan</th>
                    <th>Laufzeit</th>
                    <th>Betrag</th>
                    <th>Aktionen</th>
                </tr>
            </thead>
            <tbody class="application-list__body">
                <tr *ngFor="let item of payments">
                    <td>{{ item?.updateTime | date:'dd LLLL yyyy' }}</td>
                    <td>{{ item?.descriptionPayment }}</td>
                    <td *ngIf="item?.paymentPlan === 'Yearly'">Jährlich</td>
                    <td *ngIf="item?.paymentPlan === 'Monthly'">Monatlich</td>
                    <td>{{ item?.valuePayment }} €</td>
                    <td><button class="btn btn__green" (click)="paymentDetails(item)">Details</button></td>
                </tr>
            </tbody>
        </table>
        <div class="billing" *ngIf="billingInfo">
            <div class="billing__inner">
                <div class="billing__header">
                    <i class="fa fa-check-circle"></i>
                    <h2>Bezahlung erfolgreich!</h2>
                </div>
                <div class="billing__container">
                    <div class="billing__details">
                        <div class="bold">
                            Zahlungs-ID
                        </div>
                        <div class="billing__left-auto">
                            {{ billingInfo?.id }}
                        </div>
                    </div>
                    <div class="billing__details">
                        <div class="bold">
                            Betrag
                        </div>
                        <div class="billing__left-auto">
                            {{ billingInfo?.value }} €
                        </div>
                    </div>
                    <div class="billing__details">
                        <div class="bold">
                            Bezahlt an
                        </div>
                        <div class="billing__left-auto">
                            smart-widgets
                        </div>
                    </div>
                    <div class="billing__details">
                        <div class="bold">
                            Bezahlt am
                        </div>
                        <div class="billing__left-auto">
                            {{ billingInfo?.update_time }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>