<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Mein Profil</h1>
    </div>
</div>
<div class="gray-content gray-content--small">
    <app-sidenav></app-sidenav>
</div>
<div class="white-content">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="card-content">
                    <h2 class="h2 h2__no-marin h2__light h2__small">Kontoeinstellungen</h2>
                    <form [formGroup]="profileForm" (ngSubmit)="saveProfileForm()" class="auth-form">
                        <mat-form-field appearance="fill" class="auth-form__full-width first">
                            <mat-label>Vorname</mat-label>
                            <input type="text" matInput formControlName="firstName" [value]="accountUserInfo.firstName">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="auth-form__full-width">
                            <mat-label>Nachname</mat-label>
                            <input type="text" matInput formControlName="lastName" [value]="accountUserInfo.lastName">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="auth-form__full-width">
                            <mat-label>Webseite</mat-label>
                            <input type="text" matInput formControlName="website" [value]="accountUserInfo.website">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="auth-form__full-width">
                            <mat-label>Telefon</mat-label>
                            <input type="text" matInput formControlName="phone" [value]="accountUserInfo.phone">
                        </mat-form-field>
                        <button class="btn btn--account">Änderungen speichern</button>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card-content">
                    <h2 class="h2 h2__no-marin h2__light h2__small">Passwort ändern</h2>
                    <form [formGroup]="changePWForm" (ngSubmit)="changePW()" class="auth-form">
                        <mat-form-field appearance="fill" class="auth-form__full-width">
                            <mat-label>Altes Passwort</mat-label>
                            <input type="password" matInput formControlName="currentPassword">
                            <mat-error *ngIf="changePWForm.controls.currentPassword.errors?.required">Ungültiges oder fehlendes Passwort.
                            </mat-error>
                            <mat-error *ngIf="changePWForm.controls.currentPassword.errors?.minlength?.requiredLength">
                                Das aktuelle Passwort muss mindestens 6 Zeichen lang sein.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="auth-form__full-width">
                            <mat-label>Neues Passwort</mat-label>
                            <input type="password" matInput formControlName="newPassword">
                            <mat-error *ngIf="changePWForm.controls.newPassword.errors?.required">Ungültiges oder fehlendes Passwort.</mat-error>
                            <mat-error *ngIf="changePWForm.controls.newPassword.errors?.minlength?.requiredLength">Das neue Passwort muss mindestens 6 Zeichen lang sein.</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="auth-form__full-width">
                            <mat-label>Neues Passwort wiederholen</mat-label>
                            <input type="password" matInput formControlName="confirmPassword">
                            <mat-error *ngIf="changePWForm.controls.confirmPassword.errors?.required">Ungültiges oder fehlendes Passwort.</mat-error>
                            <mat-error *ngIf="changePWForm.controls.confirmPassword.errors?.minlength?.requiredLength">
                                Die Passwortbestätigung muss aus mindestens 6 Zeichen bestehen.</mat-error>
                        </mat-form-field>

                        <mat-error class="mat-error-class"
                            *ngIf="changePWForm.hasError('passwordNotMatch') && !changePWForm.controls.confirmPassword.errors && !changePWForm.controls.newPassword.errors">
                            Die Passwortbestätigung und das neue Passwort müssen übereinstimmen.</mat-error>
                        <button class="btn btn--account" [disabled]="!changePWForm.valid">Änderungen speichern</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>