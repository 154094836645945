
import { Routes } from "@angular/router";
import { AccountComponent } from './account/account.component';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { BillingComponent } from './billing/billing.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

export const PROFILE_ROUTES: Routes = [
    {path: '', component: AccountComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
    {path: 'billing', component: BillingComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
    {path: 'billing/:id', component: BillingComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
]