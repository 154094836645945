<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Meine Widgets</h1>
    </div>
</div>
<div class="gray-content">
    <div class="container">
        <div class="applications-list">
            <ul class="applications-list__list row">
                <li class="applications-list__item col-md-4" *ngFor="let application of applications">
                    <div class="applications-list__container">
                        <div class="applications-list__hover-link">
                            <button *ngIf="application?.application_type === 'Instagram'" [routerLink]="['social-feed']"  class="btn btn__margin-right-15 btn__blue">Verwalten</button>
                            <button *ngIf="application?.application_type === 'Instagram'" [routerLink]="['social-feed/instagram/add', application.application_id]"  class="btn btn__blue">Bearbeiten</button>
                            <button *ngIf="application?.application_type === 'Youtube'" [routerLink]="['youtube']"  class="btn btn__margin-right-15 btn__blue">Verwalten</button>
                            <button *ngIf="application?.application_type === 'Youtube'" [routerLink]="['youtube/add', application.application_id]"  class="btn btn__blue">Bearbeiten</button>
                        </div>
                        <div class="applications-list__inner">
                            <div class="applications-list__header">
                                <i class="fa fa-instagram applications-list__icon"></i>
                                <div>
                                    <h3>{{ application.application_type }}</h3>
                                    <h2>{{ application.application_name }}</h2>
                                </div>
                            </div>
                            <div class="applications-list__views" *ngIf="application?.counter">
                                <div class="applications-list__views-inner">
                                    <p>Ansichten <strong>{{ application.counter }} / 200</strong></p>
                                    <p>{{ application.counterPercent }} %</p>
                                </div>
                                <mat-progress-bar mode="determinate" [value]="application.counter / 2"></mat-progress-bar>
                            </div>
                            <div class="applications-list__views" *ngIf="!application?.counter">
                                <div class="applications-list__views-inner">
                                    <p>Ansichten <strong>0 / 200</strong></p>
                                    <p>0 %</p>
                                </div>
                                <mat-progress-bar mode="determinate" value="0"></mat-progress-bar>
                            </div>
                            <div *ngIf="application.descriptionPlan" class="applications-list__description-plan">
                                {{ application.descriptionPlan }}
                            </div>
                            <div *ngIf="application.activeUntil" class="applications-list__description-plan applications-list__description-plan--no-margin">
                                Aktiv bis: {{ application.activeUntil | date:'dd LLLL yyyy' }}
                            </div>
                        </div>
                    </div>
                </li>
                <li class="applications-list__item col-md-4" >
                    <div class="applications-list__inner-add">
                        <button class="btn btn__green btn__flex-center" (click)="scrollToApplications(applicationsList)"><span class="material-icons">add</span>Widget hinzufügen</button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="white-content" #applicationsList style="min-height: 1600px;">
    <div class="container">
        <div class="applications-list">
            <ul class="applications-list__list row">
                <li class="applications-list__item col-md-4">
                    <div class="applications-list__container">
                        <div class="applications-list__hover-link">
                            <button class="btn btn__blue btn__flex-center" routerLink="social-feed/add" [state]="{ application: 'Instagram' }"><span class="material-icons">add</span> Widget hinzufügen</button>
                        </div>
                        <div class="applications-list__inner applications-list__inner--small-center">
                            <div class="applications-list__header">
                                <i class="fa fa-instagram applications-list__icon"></i>
                                <div>
                                    <h3>Instagram Feed</h3>
                                    <h2>Add posts from your Instagram account or by hashtag to your website</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="applications-list__item col-md-4">
                    <div class="applications-list__container">
                        <div class="applications-list__cooming-soon">
                            <p>Coming Soon</p>
                        </div>
                        <div class="applications-list__inner applications-list__inner--small-center">
                            <div class="applications-list__header">
                                <i class="fa fa-facebook applications-list__icon"></i>
                                <div>
                                    <h3>Facebook Feed</h3>
                                    <h2>Display posts, photos and videos from Facebook on your website</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="applications-list__item col-md-4" style="display: none;">
                    <div class="applications-list__container">
                        <div class="applications-list__cooming-soon">
                            <p>Coming Soon</p>
                        </div>
                        <div class="applications-list__inner applications-list__inner--small-center">
                            <div class="applications-list__header">
                                <i class="fa fa-youtube applications-list__icon"></i>
                                <div>
                                    <h3>YouTube Gallery</h3>
                                    <h2>Display YouTube channels and videos on your website</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="applications-list__item col-md-4" style="display: none;">
                    <div class="applications-list__container">
                        <div class="applications-list__cooming-soon">
                            <p>Coming Soon</p>
                        </div>
                        <div class="applications-list__inner applications-list__inner--small-center">
                            <div class="applications-list__header">
                                <i class="fa fa-book applications-list__icon"></i>
                                <div>
                                    <h3>Booking Reviews</h3>
                                    <h2>Reveal positive reviews on your place from Booking.com on your site</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="applications-list__item col-md-4" style="display: none;">
                    <div class="applications-list__container">
                        <div class="applications-list__cooming-soon">
                            <p>Coming Soon</p>
                        </div>
                        <div class="applications-list__inner applications-list__inner--small-center">
                            <div class="applications-list__header">
                                <i class="fa fa-google applications-list__icon"></i>
                                <div>
                                    <h3>Google Reviews</h3>
                                    <h2>Embed reviews on your place from Google right on your site</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>