import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/service/data.service';
import { YoutubeApplication } from '../model/youtubeApplication';
import { YoutubeApplicationVideos } from '../model/youtubeApplicationVideos';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {
  youtubeApplication: YoutubeApplication;
  youtubeApplicationsSubject = new Subject<any>();

  constructor(
    private dataService: DataService,
    private httpClient: HttpClient
  ) { }

  addYoutube() {
    this.dataService.addYoutubeApplication();
  }

  getChannels(item: any) {
    const API_KEY = item.google_api;
    const YOUTUBE_TOKEN = item.youtube_token;

    const youtubeUserInfo = "https://www.googleapis.com/youtube/v3/channels?part=snippet,id,status,contentDetails,statistics&forUsername=" + YOUTUBE_TOKEN + "&key=" + API_KEY;
    this.httpClient.get<any>(youtubeUserInfo).pipe(
      map((data: any) => {
        return data.items[0];
      })
    )
    .subscribe((data: any) => {
      if (data) {
        this.youtubeApplication = new YoutubeApplication({
          title: data?.snippet?.title,
          description: data?.snippet?.description,
          customUrl: data?.snippet?.customUrl,
          thumbnail: data?.snippet?.thumbnails?.medium?.url,
          subscriberCount: data?.statistics?.subscriberCount,
          videoCount: data?.statistics?.videoCount,
          viewCount: data?.statistics?.viewCount,
          videos: []
        });
        this.httpClient.get<any>("https://www.googleapis.com/youtube/v3/search?key=" + API_KEY + "&channelId=" + data.id + "&part=snippet,id&order=date&maxResults=50")
        .subscribe((dataVideos: any) => {
          let array = [];
          dataVideos.items.forEach(element => {
            array.push({
              id: element?.id?.videoId
            });
          });
        });
      }
      this.youtubeApplicationsSubject.next(this.youtubeApplication);
    });
  }
}


/*
getChannels(item: any) {
    
    const API_KEY = "AIzaSyA_a7pjm2Mz1wTBXjh2ZiCD1j2BozpWiK8";
    const youtubeUserInfo = "https://www.googleapis.com/youtube/v3/channels?part=snippet,id,status,contentDetails,statistics&forUsername=" + item.youtube_token + "&key=" + API_KEY;
    this.httpClient.get<any>(youtubeUserInfo).pipe(
      map((data: any) => {
        return data.items[0];
      })
    )
      .subscribe((data: any) => {
        if (data) {
          this.youtubeApplication = new YoutubeApplication({
            title: data?.snippet?.title,
            description: data?.snippet?.description,
            customUrl: data?.snippet?.customUrl,
            thumbnail: data?.snippet?.thumbnails?.medium?.url,
            subscriberCount: data?.statistics?.subscriberCount,
            videoCount: data?.statistics?.videoCount,
            viewCount: data?.statistics?.viewCount,
            videos: []
          });
          this.httpClient.get<any>("https://www.googleapis.com/youtube/v3/search?key=" + API_KEY + "&channelId=" + data.id + "&part=snippet,id&order=date&maxResults=50")
            .subscribe((dataVideos: any) => {
              let array = [];
              dataVideos.items.forEach(element => {
                array.push({
                  id: element?.id?.videoId
                });
              });
              let videoIDs = Array.prototype.map.call(array, s => s.id).toString();
              this.httpClient.get<any>("https://www.googleapis.com/youtube/v3/videos?part=contentDetails,statistics,snippet&id=" + videoIDs + "&key=" + API_KEY)
                .subscribe((dataDetail: any) =>{
                  dataDetail.items.forEach(element => {
                    this.youtubeApplication?.videos.push(new YoutubeApplicationVideos({
                      videoId: element?.id?.videoId,
                      publishTime: element?.snippet?.publishTime,
                      thumbnail: element?.snippet?.thumbnails?.high?.url 
                    }));
                  });
                });
            });
        }
        this.youtubeApplicationsSubject.next(this.youtubeApplication);
      });
  }
  */