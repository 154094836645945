import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavComponent } from './basic-layout/nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { MaterialModule } from './basic-layout/material/material.module';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ApplicationsComponent } from './applications/applications.component';
import { SocialFeedComponent } from './applications/social-feed/social-feed.component';
import { AddComponent } from './applications/social-feed/add/add.component';
import { EditComponent } from './applications/social-feed/instagram/edit/edit.component';
import { Card1Component } from './applications/social-feed/instagram/cards/card1/card1.component';
import { Card2Component } from './applications/social-feed/instagram/cards/card2/card2.component';
import { Card3Component } from './applications/social-feed/instagram/cards/card3/card3.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AddToWebsiteComponent } from './modal/add-to-website/add-to-website.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PaymentComponent } from './payment/payment.component';
import { DeleteComponent } from './modal/delete/delete.component';
import { AccountComponent } from './profile/account/account.component';
import { BillingComponent } from './profile/billing/billing.component';
import { SidenavComponent } from './basic-layout/sidenav/sidenav.component';
import { FooterComponent } from './basic-layout/footer/footer.component';
import { ImpressumComponent } from './dashboard/impressum/impressum.component';
import { DatenschutzComponent } from './dashboard/datenschutz/datenschutz.component';
import { YoutubeComponent } from './applications/youtube/youtube.component';
import { AddYoutubeComponent } from './applications/youtube/add-youtube/add-youtube.component';
import { EditYoutubeComponent } from './applications/youtube/edit-youtube/edit-youtube.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavComponent,
    LoginComponent,
    RegistrationComponent,
    ApplicationsComponent,
    SocialFeedComponent,
    AddComponent,
    EditComponent,
    Card1Component,
    Card2Component,
    Card3Component,
    AddToWebsiteComponent,
    ProfileComponent,
    ResetPasswordComponent,
    PaymentComponent,
    DeleteComponent,
    AccountComponent,
    BillingComponent,
    SidenavComponent,
    FooterComponent,
    ImpressumComponent,
    DatenschutzComponent,
    YoutubeComponent,
    AddYoutubeComponent,
    EditYoutubeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    ColorPickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
