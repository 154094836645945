import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less']
})
export class AccountComponent implements OnInit {
  profileForm: FormGroup;
  changePWForm: FormGroup;
  userSubscription: Subscription;
  accountUserSubscription: Subscription;
  accountUserInfo: any  = {
    firstName: '',
    lastName: '',
    phone: '',
    website: ''
  }

  constructor(
    private authService: AuthService,
    private dataService: DataService
  ) {
    if (this.authService.activeUser) {
      this.dataService.getUserInfo(this.authService.activeUser);
      this.accountUserSubscription = this.dataService.accoountUserInfo.subscribe((dataAccountUser: any) => {
        this.accountUserInfo = {
          firstName: (dataAccountUser?.first_name) ? dataAccountUser.first_name : '',
          lastName: (dataAccountUser?.last_name) ? dataAccountUser.last_name : '',
          phone: (dataAccountUser?.phone) ? dataAccountUser.phone : '',
          website: (dataAccountUser?.website) ? dataAccountUser.website : ''
        }
      });
    } else {
      this.userSubscription = this.authService.userChanged.subscribe(userData => {
        this.dataService.getUserInfo(userData);
        this.accountUserSubscription = this.dataService.accoountUserInfo.subscribe((dataAccountUser: any) => {
          this.accountUserInfo = {
            firstName: (dataAccountUser?.first_name) ? dataAccountUser.first_name : '',
            lastName: (dataAccountUser?.last_name) ? dataAccountUser.last_name : '',
            phone: (dataAccountUser?.phone) ? dataAccountUser.phone : '',
            website: (dataAccountUser?.website) ? dataAccountUser.website : ''
          }
        });
      });
    }
  }

  ngOnInit(): void {
    this.profileForm = new FormGroup({
      firstName: new FormControl(this.accountUserInfo.firstName),
      lastName: new FormControl(this.accountUserInfo.lastName),
      phone: new FormControl(this.accountUserInfo.phone),
      website: new FormControl(this.accountUserInfo.website)
    });
    this.changePWForm = new FormGroup({
      currentPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])),
      newPassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])),
      confirmPassword: new FormControl('', Validators.compose([
        Validators.required,
        ,Validators.minLength(6)
      ]))
    }, { 
      validators: this.password.bind(this)
    });
  }

  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('newPassword');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  saveProfileForm() {
    this.dataService.editUserInfo(this.profileForm);
  }

  changePW() {
    this.authService.changePW(this.changePWForm);
  }

  ngOnDestroy() {
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.accountUserSubscription) this.accountUserSubscription.unsubscribe();
  }
}