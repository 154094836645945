import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from "@angular/fire/auth";
import { UiService } from '../service/ui.service';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import { Router } from '@angular/router';

export interface User {
  uid: string;
  email: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  activeUser: User;
  userChanged = new Subject<User>();

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public uiService: UiService,
    public router: Router
  ) { }

  initAuthListener() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userChanged.next({
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified
        });
        this.activeUser = {
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified
        }
      } else {
        this.userChanged.next(null);
        this.activeUser = null;
      }
    });
  }

  signUpWithEmail(email: string, password: string) {
    this.uiService.loadingStateChanged.next(true);
    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result: any) => {
      }).catch((error) => {
        this.uiService.loadingStateChanged.next(false);
        this.uiService.showSnackbar(error.message, null, 10000);
      });
  }

  signInWithEmail(email: string, password: string) {
    this.uiService.loadingStateChanged.next(true);
    this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result: any) => {
        this.router.navigateByUrl('applications');
      }).catch((error) => {
        this.uiService.loadingStateChanged.next(false);
        this.uiService.showSnackbar(error.message, null, 10000);
      });
  }

  reauthenticate(currentPassword: any) {
    let user = firebase.auth().currentUser;
    let cred = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  changePW(data: any) {
    this.uiService.loadingStateChanged.next(true);
    this.reauthenticate(data.value.currentPassword).then(() => {
      let user = firebase.auth().currentUser;
      user.updatePassword(data.value.newPassword).then(() => {
        this.uiService.showSnackbar('Your password was changed successfully.', null, 3000);
        this.uiService.loadingStateChanged.next(false);
      }).catch((error) => {
        this.uiService.showSnackbar(error.message, null, 10000);
        this.uiService.loadingStateChanged.next(false);
      });
    }).catch((error) => {
      this.uiService.showSnackbar(error.message, null, 10000);
      this.uiService.loadingStateChanged.next(false);
    });
  }

  resetPW(mail: string) {
    this.uiService.loadingStateChanged.next(true);
    return this.afAuth.sendPasswordResetEmail(mail)
    .then(() => {
      this.uiService.showSnackbar('Password reset email sent, check your inbox.', null, 3000);
      this.uiService.loadingStateChanged.next(false);
      this.router.navigate(['/login']);
    }).catch((error) => {
      this.uiService.showSnackbar(error.message, null, 10000);
      this.uiService.loadingStateChanged.next(false);
    });
  }

  logout() {
    this.afAuth.signOut();
    window.location.reload();
  }
}
