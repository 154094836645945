<div class="footer" *ngIf="nav.visibleFooter">
    <div class="container">
        <div class="footer__content">
            <ul class="footer-list">
                <li class="footer-list__item footer-list__item--first">
                    <a [routerLink]="['/impressum']" class="footer-list__link" routerLinkActive="active">
                        Impressum
                    </a>
                </li>
                <li class="footer-list__item">
                    <a [routerLink]="['/datenschutz']" class="footer-list__link" routerLinkActive="active">
                        Datenschutz
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>