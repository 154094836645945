<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container hero-container-title__flex">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Social Feed</h1>
        <button class="btn btn__transparent btn__left-auto btn--no-padding" [routerLink]="['/applications']">Zurück</button>
    </div>
</div>
<div class="white-content">
    <div class="container">
        <table class="application-list">
            <thead class="application-list__header">
                <tr>
                    <th>Account</th>
                    <th>Installation</th>
                    <th>Ansichten</th>
                    <th>Zahlungsmethode</th>
                    <th>Aktionen</th>
                </tr>
            </thead>
            <tbody class="application-list__body">
                <tr *ngFor="let application of applications">
                    <td>{{ application.application_name }}</td>
                    <td><button class="btn btn__outline--dark" (click)="addtoWebsite(application)">Zur Website hinzufügen</button></td>
                    <td>
                        <div class="applications-list__views" *ngIf="application?.counter">
                            <div class="applications-list__views-inner">
                                <p>Ansichten <strong>{{ application.counter }} / 200</strong></p>
                                <p>{{ application.counterPercent }} %</p>
                            </div>
                            <mat-progress-bar mode="determinate" [value]="application.counter / 2"></mat-progress-bar>
                        </div>
                        <div class="applications-list__views" *ngIf="!application?.counter">
                            <div class="applications-list__views-inner">
                                <p>Ansichten <strong>0 / 200</strong></p>
                                <p>0 %</p>
                            </div>
                            <mat-progress-bar mode="determinate" value="0"></mat-progress-bar>
                        </div>
                    </td>
                    <td>
                        <button *ngIf="application.descriptionPlan === 'Lite'" class="btn btn__green" (click)="upgradePlan(application)">{{ application.descriptionPlan }} Upgrade plan</button>
                        <button *ngIf="application.descriptionPlan !== 'Lite'" class="btn btn__green" (click)="extendPlan(application)">{{ application.descriptionPlan }} Extend plan</button>
                    </td>
                    <td>
                        <button [routerLink]="['instagram/add', application.application_id]" class="btn btn__transparent--dark">Bearbeiten</button>
                        <button (click)="removeItem(application)" class="btn btn__transparent--dark">Löschen</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>