<a [href]="data?.permalink" target="_blank" class="instagram-feed__link">
    <div class="instagram-feed__img-container instagram-feed__img-container--card-3">
        <div class="instagram-feed__img-container-inner instagram-feed__img-container-inner--card-3">
            <div class="instagram-feed__img-container-absolute">
                <div class="instagram-feed__img-container-absolute-inner">
                    <div class="instagram-feed__img-container-absolute-inner">
                        <img [src]="data.media_url" class="instagram-feed__img">
                    </div>
                </div>
            </div>
        </div>
        <div class="instagram-feed__icon" *ngIf="config.instagramLogo">
            <i class="fa fa-instagram"></i>
        </div>
        <div class="instagram-feed__overlay">
            <div class="instagram-feed__overlay-bottom">
                <div class="instagram-feed__list-header instagram-feed__list-header--card-3">    
                    <div class="instagram-feed__list-header-flex">
                        <div>
                            <div *ngIf="config.authorName" class="instagram-feed__username instagram-feed__username--card-3" [ngStyle]="{'color': fonts.color.author, 'font-size': fonts.size.author + 'px'}">@{{ data.username }}</div>
                            <div *ngIf="config.date" class="instagram-feed__date instagram-feed__date--card-3" [ngStyle]="{'color': fonts.color.date, 'font-size': fonts.size.date + 'px'}">{{ data.timestamp | date:'dd LLLL yyyy' }}</div>
                        </div>
                    </div>
                    <div *ngIf="config.description" class="instagram-feed__caption instagram-feed__caption--card-3" >
                        <div [ngStyle]="{'color': fonts.color.description, 'font-size': fonts.size.description + 'px'}" [innerHTML]="text"></div>
                    </div>
                </div>
                <div class="instagram-feed__like-comment instagram-feed__like-comment--card-3" *ngIf="config.actionBars">
                    <div class="instagram-feed__icons">
                        <div class="instagram-feed__icon-container instagram-feed__icon-container--card-3">
                            <i class="fa fa-heart-o"></i>
                            <span>Like</span>
                        </div>
                        <div class="instagram-feed__icon-container instagram-feed__icon-container--card-3">
                            <i class="fa fa-comment-o"></i>
                            <span>Comment</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>