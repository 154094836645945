<div class="instagram-feed__list-header" [ngStyle]="{'background-color': bg }"
    *ngIf="config.instagramLogo || config.authorName || config.date || config.description || config.actionBars">
    <div class="instagram-feed__list-header-flex">
        <div>
            <a [href]="data?.permalink" target="_blank" class="instagram-feed__link">
                <div *ngIf="config.authorName" class="instagram-feed__username"
                    [ngStyle]="{'color': fonts.color.author, 'font-size': fonts.size.author + 'px'}">
                    @{{ data.username }}
                </div>
                <div *ngIf="config.date" class="instagram-feed__date"
                    [ngStyle]="{'color': fonts.color.date, 'font-size': fonts.size.date + 'px'}">
                    {{ data.timestamp | date:'dd LLLL yyyy' }}</div>
            </a>
        </div>
        <div class="icon" *ngIf="config.instagramLogo">
            <a [href]="data?.permalink" target="_blank" class="instagram-feed__link">
                <i class="fa fa-instagram"></i>
            </a>
        </div>
    </div>
    <div *ngIf="config.description" class="instagram-feed__caption">
        <div [ngStyle]="{'color': fonts.color.description, 'font-size': fonts.size.description + 'px'}" [innerHTML]="text"></div>
    </div>

    <span *ngIf="config.description && caption" class="instagram-feed__more" (click)="toggle()" [ngStyle]="{'color': fonts.color.author }">{{ buttonName }}</span>
</div>
<div class="instagram-feed__img-container">
    <div class="instagram-feed__img-container-inner">
        <div class="instagram-feed__img-container-absolute">
            <div class="instagram-feed__img-container-absolute-inner">
                <div class="instagram-feed__img-container-absolute-inner">
                    <a [href]="data?.permalink" target="_blank" class="instagram-feed__link">
                        <img [src]="data.media_url" class="instagram-feed__img">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="instagram-feed__like-comment" *ngIf="config.actionBars" [ngStyle]="{'background-color': bg }">
    <div class="instagram-feed__icons">
        <div class="instagram-feed__icon-container">
            <a [href]="data?.permalink" target="_blank" class="instagram-feed__link" [ngStyle]="{'color': fonts.color.author}">
                <i class="fa fa-heart-o"></i>
                <span>Like</span>
            </a>
        </div>
        <div class="instagram-feed__icon-container">
            <a [href]="data?.permalink" target="_blank" class="instagram-feed__link" [ngStyle]="{'color': fonts.color.author}">
                <i class="fa fa-comment-o"></i>
                <span>Comment</span>
            </a>
        </div>
    </div>
</div>