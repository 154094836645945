<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Passwort zurücksetzen</h1>
    </div>
</div>

<div class="content-auth">
    <div class="container">
        <div class="row">
            <div class="col-md-6 m-auto">
                <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" class="auth-form">
                    <h1 class="h1__margin-bottom-30 h1__small">Passwort zurücksetzen</h1>
                    <mat-form-field appearance="fill" class="auth-form__full-width first">
                        <mat-label>E-Mail Adresse</mat-label>
                        <input type="email" matInput placeholder="E-Mail Adresse" formControlName="email">
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="resetForm.controls.email.errors?.required">Ungültige oder fehlende E-Mail Adresse.</mat-error>
                        <mat-error *ngIf="resetForm.controls.email.errors?.email">Bitte geben Sie eine gültige E-Mail-Adresse ein.</mat-error>
                    </mat-form-field>
        
                    <button class="btn btn--auth" [disabled]="resetForm.invalid">Passwort zurücksetzen <div class="sk-chase" *ngIf="isLoading"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div></button>
                </form>
            </div>
        </div>
    </div>
</div>