import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.less']
})
export class BillingComponent implements OnInit {
  billingInfo: any;
  payments: any = [];
  userSubscription: Subscription;
  billingSubscription: Subscription;
  paymentSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (this.authService.activeUser) {
      this.loadFunction(this.authService.activeUser);
    } else {
      this.userSubscription = this.authService.userChanged.subscribe(userData => {
        this.loadFunction(userData);
      });
    }
    this.paymentSubscription = this.dataService.myPayments.subscribe(data => {
      this.payments = data;
    });
  }

  loadFunction(userData: any) {
    this.dataService.getPayment(userData);
    this.route.paramMap.subscribe((params: any) => {
      if (params.params.id !== undefined) {
        this.dataService.getBillingInfo(params.params.id, userData.uid);
        this.billingSubscription = this.dataService.billingInfo.subscribe((data: any) => {
          this.billingInfo = data;
        });
      }
    });
  }

  paymentDetails(item: any) {
    this.router.navigate(['/profile/billing/' + item.paymentID]);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.paymentSubscription) this.paymentSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.billingSubscription) this.billingSubscription.unsubscribe();
  }
}
