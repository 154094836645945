import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/service/navbar.service';
import { UiService } from 'src/app/service/ui.service';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.less']
})
export class RegistrationComponent implements OnInit {
  loginForm: FormGroup;
  isLoading = false;
  emailValue: string = '';
  private loadingSubs: Subscription;

  constructor(
    private authService: AuthService,
    private uiService: UiService,
    private route: ActivatedRoute,
    private navService: NavbarService
  ) {
    this.navService.hideFooter();
    this.route.paramMap.subscribe((params: any) => {
      if (params.params.email !== undefined) {
        this.emailValue = params.params.email;
      }
    });
  }

  ngOnInit(): void {
    this.loadingSubs = this.uiService.loadingStateChanged.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading
    });
    this.loginForm = new FormGroup({
      email: new FormControl(this.emailValue, {
        validators: [
          Validators.required, 
          Validators.email
        ]
      }),
      password: new FormControl('', { 
        validators: [
          Validators.required,
          Validators.minLength(6)
        ] })
    });
  }

  onSubmit() {
    this.authService.signUpWithEmail(this.loginForm.value.email, this.loginForm.value.password);
  }

  ngOnDestroy() {
    this.navService.showFooter();
    if (this.loadingSubs) {
      this.loadingSubs.unsubscribe();
    }
  }
}
