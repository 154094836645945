<div class="top-bar">
    <div class="top-bar__widget">
        <input type="text" [(ngModel)]="applicationName">
    </div>
    <div class="top-bar__viewport">
        <button class="btn btn__outline" (click)="addtoWebsite()">Zur Website hinzufügen</button>
    </div>
    <div class="top-bar__control">
        <button class="btn btn__transparent" (click)="cancel()">Abbrechen</button>
        <button class="btn btn__green btn__margin-right-15" (click)="save()">Speichern</button>
    </div>
</div>

<div class="main-app">
    <div class="main-app__sidebar">
        <div class="main-app__menu-container">
            <div>
                <button class="main-app__btn" [ngClass]="{'active':instagramConfig.tabSwitch?.sources === true}"
                    (click)="switchTab('sources')">
                    <span class="material-icons">repeat</span>
                    <div class="main-app__btn-text">Quellen</div>
                </button>
                <button class="main-app__btn" [ngClass]="{'active':instagramConfig.tabSwitch?.layout === true}"
                    (click)="switchTab('layout')">
                    <span class="material-icons">view_quilt</span>
                    <div class="main-app__btn-text">Layout</div>
                </button>
                <button class="main-app__btn" [ngClass]="{'active':instagramConfig.tabSwitch?.design === true}"
                    (click)="switchTab('design')">
                    <span class="material-icons">color_lens</span>
                    <div class="main-app__btn-text">Design</div>
                </button>
                <button class="main-app__btn" [ngClass]="{'active':instagramConfig.tabSwitch?.font === true}"
                    (click)="switchTab('font')">
                    <span class="material-icons">text_fields</span>
                    <div class="main-app__btn-text">Schrift</div>
                </button>
            </div>
        </div>
        <div class="main-app__menu-container-outer">
            <div class="main-app__outer-container">
                <div class="main-app__outer-container-inner" *ngIf="instagramConfig.tabSwitch?.sources === true">
                    <div class="main-app__outer-title">
                        <h3>Verbundene Quelle</h3>
                    </div>
                    <div class="main-app__outer-source-container">
                        <div class="main-app__outer-source-container-bg">
                            <div class="main-app__outer-source-container-logged-in">
                                <div>
                                    <i class="fa fa-instagram"></i>
                                </div>
                                <div>
                                    <h4 class="no-margin">Instagram</h4>
                                    <p>Connected {{ instagramApplications?.account_type }} Account</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-app__outer-container-inner" *ngIf="instagramConfig.tabSwitch?.layout === true">
                    <div class="main-app__outer-flex">
                        <div class="main-app__outer-title">
                            <h3>Layout</h3>
                        </div>
                        <div class="main-app__outer-source-container">
                            <div class="main-app__outer-source-container-bg">
                                <h4>Layout</h4>
                                <div class="main-app__grid-container">
                                    <div class="main-app__grid-container-option"
                                        [ngClass]="{'active':instagramConfig.layout?.grid === true}"
                                        (click)="switchLayout('grid')">
                                        <span class="material-icons">view_module</span>
                                        <div class="main-app__grid-container-text">Grid</div>
                                    </div>
                                    <div class="main-app__grid-container-option"
                                        [ngClass]="{'active':instagramConfig.layout?.list === true}"
                                        (click)="switchLayout('list')">
                                        <span class="material-icons">view_stream</span>
                                        <div class="main-app__grid-container-text">List</div>
                                    </div>
                                    <div class="main-app__grid-container-option"
                                        [ngClass]="{'active':instagramConfig.layout?.masonry === true}"
                                        (click)="switchLayout('masonry')">
                                        <span class="material-icons">view_quilt</span>
                                        <div class="main-app__grid-container-text">Masonry</div>
                                    </div>
                                </div>
                            </div>

                            <mat-accordion class="main-app__expansion">
                                <mat-expansion-panel hideToggle>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Anpassen
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <mat-icon>keyboard_arrow_right</mat-icon>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div class="dropdown-container">
                                        <div class="dropdown-text">Columns</div>
                                        <div class="dropdown">
                                            <select [(ngModel)]="instagramConfig.layout.customize.columns"
                                                class="dropdown-select" (change)="switchColumns()">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="dropdown-container">
                                        <div class="dropdown-text">Rows</div>
                                        <div class="dropdown">
                                            <select [(ngModel)]="instagramConfig.layout.customize.rows"
                                                class="dropdown-select" (change)="switchRows()">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="dropdown-container">
                                        <div class="dropdown-text">Gap</div>
                                        <div class="dropdown">
                                            <select [(ngModel)]="instagramConfig.layout.customize.gap"
                                                class="dropdown-select" (change)="switchGap()">
                                                <option value="0">0</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="40">40</option>
                                                <option value="50">50</option>
                                            </select>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>

                            <div
                                class="main-app__outer-source-container-bg main-app__outer-source-container-bg--top-30">
                                <h4>Card Layout</h4>
                                <div class="main-app__grid-container main-app__grid-container--card">
                                    <div class="main-app__grid-container-option"
                                        [ngClass]="{'active':instagramConfig.cardLayout?.card1 === true}"
                                        (click)="switchCardLayout('card1')">
                                        <div class="main-app__grid-container-text">Card 1</div>
                                    </div>
                                    <div class="main-app__grid-container-option"
                                        [ngClass]="{'active':instagramConfig.cardLayout?.card2 === true}"
                                        (click)="switchCardLayout('card2')">
                                        <div class="main-app__grid-container-text">Card 2</div>
                                    </div>
                                    <div class="main-app__grid-container-option"
                                        [ngClass]="{'active':instagramConfig.cardLayout?.card3 === true}"
                                        (click)="switchCardLayout('card3')">
                                        <div class="main-app__grid-container-text">Card 3</div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="main-app__outer-source-container-bg main-app__outer-source-container-bg--top-30 main-app__outer-source-container-bg--no-padding">
                                <!--
                                <section class="toggle-section">
                                    <mat-slide-toggle [color]="color" [(ngModel)]="instagramConfig.toggles.userHeader">
                                        Instagram-Header anzeigen
                                    </mat-slide-toggle>
                                </section>
                                -->
                                <section class="toggle-section">
                                    <mat-slide-toggle [color]="color" [(ngModel)]="instagramConfig.toggles.authorName">
                                        Autorennamen anzeigen
                                    </mat-slide-toggle>
                                </section>
                                <section class="toggle-section">
                                    <mat-slide-toggle [color]="color" [(ngModel)]="instagramConfig.toggles.date">
                                        Datum anzeigen
                                    </mat-slide-toggle>
                                </section>
                                <section class="toggle-section">
                                    <mat-slide-toggle [color]="color" [(ngModel)]="instagramConfig.toggles.description">
                                        Beschreibung anzeigen
                                    </mat-slide-toggle>
                                </section>
                                <section class="toggle-section">
                                    <mat-slide-toggle [color]="color" [(ngModel)]="instagramConfig.toggles.actionBars">
                                        Aktionsleisten anzeigen
                                    </mat-slide-toggle>
                                </section>
                                <section class="toggle-section">
                                    <mat-slide-toggle [color]="color"
                                        [(ngModel)]="instagramConfig.toggles.instagramLogo">
                                        Instagram Logo anzeigen
                                    </mat-slide-toggle>
                                </section>
                                <section class="toggle-section">
                                    <mat-slide-toggle [color]="color"
                                        [(ngModel)]="instagramConfig.toggles.loadMore">
                                        Mehr laden anzeigen
                                    </mat-slide-toggle>
                                </section>
                            </div>
                        </div>
                    </div>
                    <div class="main-app__outer-bottom">
                        <button class="btn" (click)="publish()">Auf der Website veröffentlichen</button>
                    </div>
                </div>
                <div class="main-app__outer-container-inner" *ngIf="instagramConfig.tabSwitch?.design === true">
                    <div class="main-app__outer-flex">
                        <div class="main-app__outer-title">
                            <h3>Design</h3>
                        </div>
                        <div class="main-app__outer-source-container">
                            <div
                                class="main-app__outer-source-container-bg main-app__outer-source-container-bg--no-padding">
                                <div class="toggle-section">
                                    <div class="flex">
                                        <div class="title">
                                            Widget Hintergrund
                                        </div>
                                        <div class="main-app__color-picker">
                                            <div class="main-app__color-picker-btn"
                                                (colorPickerChange)="changeLayoutBG($event)"
                                                [style.background]="instagramConfig?.layout?.bg"
                                                [colorPicker]="instagramConfig?.layout?.bg" [cpPosition]="'bottom-left'"
                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-section">
                                    <div class="flex">
                                        <div class="title">
                                            Card Hintergrund
                                        </div>
                                        <div class="main-app__color-picker">
                                            <div class="main-app__color-picker-btn"
                                                (colorPickerChange)="changePostBG($event)"
                                                [style.background]="instagramConfig?.cardLayout?.bg"
                                                [colorPicker]="instagramConfig?.cardLayout?.bg"
                                                [cpPosition]="'bottom-left'"
                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-app__outer-bottom">
                        <button class="btn" (click)="publish()">Auf der Website veröffentlichen</button>
                    </div>
                </div>

                <div class="main-app__outer-container-inner" *ngIf="instagramConfig.tabSwitch?.font === true">
                    <div class="main-app__outer-flex">
                        <div class="main-app__outer-title">
                            <h3>Font</h3>
                        </div>
                        <div class="main-app__outer-source-container">
                            <div
                                class="main-app__outer-source-container-bg main-app__outer-source-container-bg--no-padding">
                                <!--
                                <div class="toggle-section">
                                    <div class="dropdown-container">
                                        <div class="dropdown-text">Author Header Schriftgröße</div>
                                        <div class="dropdown">
                                            <select [(ngModel)]="instagramConfig.fonts.size.authorHeader"
                                                class="dropdown-select">
                                                <option value="10">10px</option>
                                                <option value="11">11px</option>
                                                <option value="12">12px</option>
                                                <option value="13">13px</option>
                                                <option value="14">14px</option>
                                                <option value="15">15px</option>
                                                <option value="16">16px</option>
                                                <option value="18">18px</option>
                                                <option value="24">24px</option>
                                                <option value="36">36px</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                -->
                                <div class="toggle-section">
                                    <div class="dropdown-container">
                                        <div class="dropdown-text">Author Schriftgröße</div>
                                        <div class="dropdown">
                                            <select [(ngModel)]="instagramConfig.fonts.size.author"
                                                class="dropdown-select">
                                                <option value="10">10px</option>
                                                <option value="11">11px</option>
                                                <option value="12">12px</option>
                                                <option value="13">13px</option>
                                                <option value="14">14px</option>
                                                <option value="15">15px</option>
                                                <option value="16">16px</option>
                                                <option value="18">18px</option>
                                                <option value="24">24px</option>
                                                <option value="36">36px</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-section">
                                    <div class="dropdown-container">
                                        <div class="dropdown-text">Datum Schriftgröße</div>
                                        <div class="dropdown">
                                            <select [(ngModel)]="instagramConfig.fonts.size.date"
                                                class="dropdown-select">
                                                <option value="10">10px</option>
                                                <option value="11">11px</option>
                                                <option value="12">12px</option>
                                                <option value="13">13px</option>
                                                <option value="14">14px</option>
                                                <option value="15">15px</option>
                                                <option value="16">16px</option>
                                                <option value="18">18px</option>
                                                <option value="24">24px</option>
                                                <option value="36">36px</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-section">
                                    <div class="dropdown-container">
                                        <div class="dropdown-text">Beschreibung Schriftgröße</div>
                                        <div class="dropdown">
                                            <select [(ngModel)]="instagramConfig.fonts.size.description"
                                                class="dropdown-select">
                                                <option value="10">10px</option>
                                                <option value="11">11px</option>
                                                <option value="12">12px</option>
                                                <option value="13">13px</option>
                                                <option value="14">14px</option>
                                                <option value="15">15px</option>
                                                <option value="16">16px</option>
                                                <option value="18">18px</option>
                                                <option value="24">24px</option>
                                                <option value="36">36px</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="main-app__outer-source-container-bg main-app__outer-source-container-bg--no-padding">
                                <!--
                                <div class="toggle-section">
                                    <div class="flex">
                                        <div class="title">
                                            Author Header Textfarbe
                                        </div>
                                        <div class="main-app__color-picker">
                                            <div class="main-app__color-picker-btn"
                                                (colorPickerChange)="changeColorAuthorHeader($event)"
                                                [style.background]="instagramConfig?.fonts?.color?.authorHeader"
                                                [colorPicker]="instagramConfig?.fonts?.color?.authorHeader"
                                                [cpPosition]="'bottom-left'"
                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                -->
                                <div class="toggle-section">
                                    <div class="flex">
                                        <div class="title">
                                            Author Textfarbe
                                        </div>
                                        <div class="main-app__color-picker">
                                            <div class="main-app__color-picker-btn"
                                                (colorPickerChange)="changeColorAuthor($event)"
                                                [style.background]="instagramConfig?.fonts?.color?.author"
                                                [colorPicker]="instagramConfig?.fonts?.color?.author"
                                                [cpPosition]="'bottom-left'"
                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-section">
                                    <div class="flex">
                                        <div class="title">
                                            Datum Textfarbe
                                        </div>
                                        <div class="main-app__color-picker">
                                            <div class="main-app__color-picker-btn"
                                                (colorPickerChange)="changeColorDate($event)"
                                                [style.background]="instagramConfig?.fonts?.color?.date"
                                                [colorPicker]="instagramConfig?.fonts?.color?.date"
                                                [cpPosition]="'bottom-left'"
                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="toggle-section">
                                    <div class="flex">
                                        <div class="title">
                                            Beschreibung Textfarbe
                                        </div>
                                        <div class="main-app__color-picker">
                                            <div class="main-app__color-picker-btn"
                                                (colorPickerChange)="changeColorDescription($event)"
                                                [style.background]="instagramConfig?.fonts?.color?.description"
                                                [colorPicker]="instagramConfig?.fonts?.color?.description"
                                                [cpPosition]="'bottom-left'"
                                                [cpPresetColors]="['#fff', '#000', '#2889e9', '#e920e9', '#fff500', 'rgb(236,64,64)']">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-app__outer-bottom">
                        <button class="btn" (click)="publish()">Auf der Website veröffentlichen</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="main-app__container">
        <div class="instagram-feed">
            <div class="instagram-feed__container" [ngStyle]="{'background-color': instagramConfig?.layout?.bg }">
                <div class="container">
                    <!--
                    <div class="instagram-feed__header" *ngIf="instagramConfig.toggles.userHeader">
                        <div class="instagram-feed__header-logo">
                            <a href="https://www.instagram.com/{{instagamHeader?.username}}/" target="_blank">
                                <img [src]="instagamHeader?.profile_pic_url" class="instagram-feed__header-img">
                            </a>
                        </div>
                        <div class="instagram-feed__header-inner">
                            <a href="https://www.instagram.com/{{instagamHeader?.username}}/" target="_blank">
                                <h4 [ngStyle]="{'color': instagramConfig?.fonts?.color?.authorHeader, 'font-size': instagramConfig?.fonts?.size?.authorHeader + 'px'}">{{ instagamHeader?.username }} | {{ instagamHeader?.media_count }} Beiträge</h4>
                                <h3 [ngStyle]="{'color': instagramConfig?.fonts?.color?.authorHeader, 'font-size': instagramConfig?.fonts?.size?.authorHeader + 'px'}">{{ instagamHeader?.full_name }}</h3>
                            </a>
                        </div>
                    </div>
                    -->
                    <ul class="instagram-feed__list" [className]="instagramConfig?.instagramFeedClass?.list">
                        <li *ngFor="let instagram of instagramPostsActive" class="instagram-feed__item"
                            [className]="instagramConfig.instagramFeedClass.item">
                            <app-card1 [data]="instagram" [config]="instagramConfig?.toggles"
                                [fonts]="instagramConfig?.fonts" [bg]="instagramConfig?.cardLayout?.bg"
                                *ngIf="instagramConfig.cardLayout?.card1 === true">
                            </app-card1>
                            <app-card2 [data]="instagram" [config]="instagramConfig?.toggles"
                                [fonts]="instagramConfig?.fonts" [bg]="instagramConfig?.cardLayout?.bg"
                                *ngIf="instagramConfig.cardLayout?.card2 === true">
                            </app-card2>
                            <app-card3 [data]="instagram" [config]="instagramConfig?.toggles"
                                [fonts]="instagramConfig?.fonts" [bg]="instagramConfig?.cardLayout?.bg"
                                *ngIf="instagramConfig.cardLayout?.card3 === true">
                            </app-card3>
                        </li>
                    </ul>
                    <div class="instagram-feed__btn-center" *ngIf="instagramConfig?.toggles?.loadMore">
                        <button class="btn" *ngIf="btnVisible" (click)="loadMore()" [ngStyle]="{'background-color': instagramConfig.cardLayout.bg, 'color': instagramConfig.fonts.color.author }">Mehr laden</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>