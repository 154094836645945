import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AddToWebsiteComponent } from 'src/app/modal/add-to-website/add-to-website.component';
import { DeleteComponent } from 'src/app/modal/delete/delete.component';
import { InstagramApplication } from 'src/app/model/instagramApplication';
import { AuthService } from 'src/app/service/auth.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'app-social-feed',
  templateUrl: './social-feed.component.html',
  styleUrls: ['./social-feed.component.less']
})
export class SocialFeedComponent implements OnInit {
  applications: [];
  instagramApplications: InstagramApplication[];
  userSubscription: Subscription;
  applicationsSubscription: Subscription;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (this.authService.activeUser) {
      this.loadFunction(this.authService.activeUser);
    } else {
      this.userSubscription = this.authService.userChanged.subscribe(userData => {
        this.loadFunction(userData);
      });
    }
  }

  upgradePlan(instagram: any) {
    this.router.navigate(['/payment'], { state: { item: instagram, status: 'upgrade', title: 'Wählen Sie Ihren Social Feed Plan' } } );
  }

  extendPlan(instagram: any) {
    this.router.navigate(['/payment'], { state: { item: instagram, status: 'extend', title: 'Erweitern Sie Ihren Social Feed Plan' } } );
  }

  addtoWebsite(item: any) {
    const dialogRef = this.dialog.open(AddToWebsiteComponent, {
      data: {
        id: item.application_id
      },
      panelClass: 'add-to-website'
    });
  }

  loadFunction(id: any) {
    this.dataService.getApplications(id);
    this.applicationsSubscription = this.dataService.applicationsSubject.subscribe((data: any) => {
      this.applications = data;
    });
  }

  removeItem(item: any) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: {
        name: item.application_name,
        type: item.application_type
      },
      panelClass: 'add-to-website'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) this.dataService.removeAppliaction(item);
    })
  }

  ngOnDestroy() {
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.applicationsSubscription) this.applicationsSubscription.unsubscribe();
  }
}