import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  visible: boolean;
  visibleFooter: boolean;

  constructor() { 
    this.visible = true;
    this.visibleFooter = true;
  }

  hide() { this.visible = false; }

  show() { this.visible = true; }

  hideFooter() { this.visibleFooter = false; }

  showFooter() { this.visibleFooter = true; }
}
