import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card3',
  templateUrl: './card3.component.html',
  styleUrls: ['./card3.component.less']
})
export class Card3Component implements OnInit {
  @Input() data;
  @Input() config;
  @Input() fonts;
  public caption: string;
  public captionShort: string;
  public text: string;
  constructor() { }

  ngOnInit(): void {
    this.truncate(this.data);
  }

  truncate(data: any) {
    let firstRow = data.caption.split("\n");
    let firstRowLength = firstRow[0].split(" ").splice(0, 15).join(" ");
    this.captionShort = this.addLink(firstRowLength);
    this.text = this.captionShort;
    if (firstRow[1]) {
      this.caption = this.addBreak(this.data.caption);
      this.caption = this.addLink(this.caption);
    }
  }

  addBreak(str: any) {
    return str.split("\n").join("<br>");
  }

  addLink(str: any) {
    let repl = str.replace(/#(\w+)/g, '<a href="https://www.instagram.com/explore/tags/$1/" target="_blank">#$1</a>');
    return repl;
  }
}
