import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { UiService } from 'src/app/service/ui.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  isLoading = false;
  private loadingSubs: Subscription;

  constructor(
    private authService: AuthService,
    private uiService: UiService,
    private navService: NavbarService
  ) { 
    this.navService.hideFooter();
  }

  ngOnInit(): void {
    this.loadingSubs = this.uiService.loadingStateChanged.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading
    });
    this.resetForm = new FormGroup({
      email: new FormControl('', {
        validators: [
          Validators.required, 
          Validators.email
        ]
      }),
    });

  }

  onSubmit() {
    this.authService.resetPW(this.resetForm.value.email);
  }

  ngOnDestroy() {
    this.navService.showFooter();
    if (this.loadingSubs) {
      this.loadingSubs.unsubscribe();
    }
  }
}
