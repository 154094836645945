import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card2',
  templateUrl: './card2.component.html',
  styleUrls: ['./card2.component.less']
})
export class Card2Component implements OnInit {
  @Input() data;
  @Input() config;
  @Input() bg;
  @Input() fonts;
  public show: boolean = false;
  public buttonName: string = 'Weiterlesen';
  public caption: string;
  public captionShort: string;
  public text: string;
  constructor() { }

  ngOnInit(): void {
    this.truncate(this.data);
  }

  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = 'Ausblenden';
      this.text = this.caption;
    } else {
      this.buttonName = 'Weiterlesen';
      this.text = this.captionShort;
    }
  }

  truncate(data: any) {
    if (data.caption) {
      let firstRow = data.caption.split("\n");
      let firstRowLength = firstRow[0].split(" ").splice(0, 15).join(" ");
      this.captionShort = this.addLink(firstRowLength);
      this.text = this.captionShort;
      if (firstRow.length > 1) {
        this.caption = this.addBreak(this.data.caption);
        this.caption = this.addLink(this.caption);
      }
    }
  }

  addBreak(str: any) {
    return str.split("\n").join("<br>");
  }

  addLink(str: any) {
    let repl = str.replace(/#(\w+)/g, '<a href="https://www.instagram.com/explore/tags/$1/" target="_blank">#$1</a>');
    return repl;
  }
}
