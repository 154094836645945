<div class="header" *ngIf="nav.visible">
    <div class="container">
        <div class="header__flex">
            <div class="header__logo">
                <a routerLink="/">
                    <img src="../../../assets/img/logos/smart-widgetes-logo.png">
                </a>
            </div>
            <ul class="header__list">
                <li class="header__item" *ngIf="!isUser?.email">
                    <a routerLink="login" class="header__link" routerLinkActive="active">
                        Anmelden
                    </a>
                </li>
                <li class="header__item" *ngIf="!isUser?.email">
                    <a routerLink="register" class="header__link header__link--register" routerLinkActive="active">
                        Kostenlos registrieren
                    </a>
                </li>
                <li class="header__item" *ngIf="isUser?.email">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item routerLink="profile">
                            <mat-icon>account_circle</mat-icon>
                            <span>Mein Profil</span>
                        </button>
                        <button mat-menu-item routerLink="applications">
                            <mat-icon>settings_applications</mat-icon>
                            <span>Meine Widgets</span>
                        </button>
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>Ausloggen</span>
                        </button>
                    </mat-menu>
                    {{ isUser?.email }}
                </li>
            </ul>
        </div>
    </div>
</div>

<router-outlet></router-outlet>