<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">{{ title }}</h1>
    </div>
</div>

<div class="gray-content">
    <div class="container">
        <div class="payment-plan"
            [ngClass]="purchaseUnit?.amount?.value ? 'payment-plan payment-plan--hidden' : 'payment-plan'">
            <div class="payment-plan__toggle">
                <div [className]="paymentPlan ? 'payment-plan__toggle-select' : 'payment-plan__toggle-select--active'">
                    Monatlich</div>
                <div class="payment-plan__toggle-btn">
                    <mat-slide-toggle class="example-margin" [color]="color" [(ngModel)]="paymentPlan">
                    </mat-slide-toggle>
                </div>
                <div [className]="!paymentPlan ? 'payment-plan__toggle-select' : 'payment-plan__toggle-select--active'">
                    Jährlich (spare 17%)</div>
            </div>
            <ul class="payment-plan__list">
                <li class="payment-plan__item payment-plan__item--first">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Enterprise</h3>
                            <p>For websites with heavy traffic and client work</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price" *ngIf="paymentPlan">{{ priceTable?.enterprise?.year }}</span>
                                <span class="payment-plan__body-price" *ngIf="!paymentPlan">{{ priceTable?.enterprise?.month }}</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                            <button *ngIf="paymentPlan" class="btn btn__green"
                                (click)="payment({packet: 'Enterprise', plan: paymentPlan})">Zahlen Sie jährlich</button>
                            <button *ngIf="!paymentPlan" class="btn btn__green"
                                (click)="payment({packet: 'Enterprise', plan: paymentPlan})">Auswählen</button>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>5,000,000 views</li>
                                <li>Free installation service</li>
                                <li>SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="payment-plan__item payment-plan__item--popular">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Pro</h3>
                            <p>For growing businesses</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price" *ngIf="paymentPlan">{{ priceTable?.pro?.year }}</span>
                                <span class="payment-plan__body-price" *ngIf="!paymentPlan">{{ priceTable?.pro?.month }}</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                            <button *ngIf="paymentPlan" class="btn btn__green"
                                (click)="payment({packet: 'Pro', plan: paymentPlan})">Zahlen Sie jährlich</button>
                            <button *ngIf="!paymentPlan" class="btn btn__green"
                                (click)="payment({packet: 'Pro', plan: paymentPlan})">Auswählen</button>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>50,000 views</li>
                                <li>Free installation service</li>
                                <li>SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="payment-plan__item">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Basic</h3>
                            <p>Sufficient for startups and new businesses</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price" *ngIf="paymentPlan">{{ priceTable?.basic?.year }}</span>
                                <span class="payment-plan__body-price" *ngIf="!paymentPlan">{{ priceTable?.basic?.month }}</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                            <button *ngIf="paymentPlan" class="btn btn__green"
                                (click)="payment({packet: 'Basic', plan: paymentPlan})">Zahlen Sie jährlich</button>
                            <button *ngIf="!paymentPlan" class="btn btn__green"
                                (click)="payment({packet: 'Basic', plan: paymentPlan})">Auswählen</button>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>5,000 views</li>
                                <li>Free installation service</li>
                                <li>SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="payment-plan__item payment-plan__item--last">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Lite</h3>
                            <p>Use it for free and upgrade as you grow</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price">0</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                            <button *ngIf="item?.descriptionPlan === 'Lite'" class="btn btn__transparent btn__transparent--dark btn__no-pointer">Derzeitiger Plan</button>
                            <button *ngIf="item?.descriptionPlan !== 'Lite'" class="btn btn__green"
                                (click)="payment({packet: 'Lite', plan: paymentPlan})">Select</button>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>200 views</li>
                                <li class="remove">Free installation service</li>
                                <li class="remove">SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row"
            [ngClass]="purchaseUnit?.amount?.value ? 'paypal-container paypal-container--active' : 'paypal-container paypal-container--hidden'">
            <div class="col-md-12" *ngIf="!isLoading && status !== 'extend'">
                <button (click)="resetPlan()"
                    class="btn btn__transparent btn__transparent--dark btn--padding-left-0 btn--margin-bottom-15"><i
                        class="fa fa-chevron-left"></i> Alle Pläne anzeigen</button>
            </div>
            
            <div class="col-md-12" *ngIf="isLoading">
                <div class="loader">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>

            <div class="col-md-6" *ngIf="!isLoading">
                <div class="payment-plan__details">
                    <div class="inputGroup-container">
                        <div class="inputGroup">
                            <input id="option1" name="option1" type="checkbox" (click)="changePaymentPlan()"
                                [ngModel]="paymentPlan" />
                            <label for="option1">{{ selectedPayment?.year * 12 }} €<br>Zahlen Sie jährlich und sparen Sie 17%</label>
                        </div>
                        <div class="inputGroup">
                            <input id="option2" name="option2" type="checkbox" (click)="changePaymentPlan()"
                                [ngModel]="!paymentPlan" />
                            <label for="option2">{{ selectedPayment?.month }} €<br>Zahle monatlich</label>
                        </div>
                    </div>
                    <p>Ausgewählter Plan</p>
                    <h3>{{ purchaseUnit?.description }}</h3>
                    <h3 class="detail">Details</h3>
                    <div class="payment-plan__details-list">
                        <div class="payment-plan__flex">
                            <div>
                                <p>{{ purchaseUnit?.description }} Plan</p>
                            </div>
                            <div class="payment-plan__left-auto">
                                <p *ngIf="paymentPlan">{{ selectedPayment?.year * 12 }} €</p>
                                <p *ngIf="!paymentPlan">{{ selectedPayment?.month }} €</p>
                            </div>
                        </div>
                        <div class="payment-plan__flex" *ngIf="item" style="margin-top: 15px;">
                            <div>
                                <p>Widget Typ:</p>
                            </div>
                            <div class="payment-plan__left-auto">
                                <p>{{ item.application_type }}</p>
                            </div>
                        </div>
                        <div class="payment-plan__flex" *ngIf="item">
                            <div>
                                <p>widget Name:</p>
                            </div>
                            <div class="payment-plan__left-auto">
                                <p>{{ item.application_name }}</p>
                            </div>
                        </div>
                        <div class="payment-plan__flex" *ngIf="item && paymentStatus">
                            <div>
                                <p>Widget aktiv bis:</p>
                            </div>
                            <div class="payment-plan__left-auto">
                                <p>{{ paymentStatus | date:'dd LLLL yyyy' }}</p>
                            </div>
                        </div>
                        <div class="payment-plan__flex" *ngIf="item">
                            <div *ngIf="!paymentStatus">
                                <p>Widget aktiv bis:</p>
                            </div>
                            <div *ngIf="paymentStatus">
                                <p>Widget verlängerung aktiv bis:</p>
                            </div>
                            <div class="payment-plan__left-auto">
                                <p>{{ runningTime | date:'dd LLLL yyyy' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="payment-plan__details-footer">
                        <div>
                            <p *ngIf="paymentPlan" class="bold">Jährliche Summe</p>
                            <p *ngIf="!paymentPlan" class="bold">Monatliche Summe</p>
                        </div>
                        <div *ngIf="purchaseUnit?.description" class="payment-plan__left-auto">
                            <p *ngIf="paymentPlan" class="bold big">{{ selectedPayment?.year * 12 }} €</p>
                            <p *ngIf="!paymentPlan" class="bold big">{{ selectedPayment?.month }} €</p>
                        </div>
                    </div>
                    <div *ngIf="purchaseUnit?.description" class="payment-plan__invoice">
                        <p *ngIf="paymentPlan">You’ll be charged {{ selectedPayment?.year * 12 }} € today for
                            upgrading to {{ purchaseUnit?.description }}.</p>
                        <p *ngIf="!paymentPlan">You’ll be charged {{ selectedPayment?.month }} € today for
                            upgrading to {{ purchaseUnit?.description }}.</p>
                    </div>

                    <label class="payment-plan__label">
                        <input type="checkbox" [(ngModel)]="terms" name="terms">
                        <span>Ich stimme der <a href="#" target="_blank">Datenschutzerklärung</a> und den <a href="#" target="_blank">ABG´s</a> auf smart-widgets zu.</span>
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div [ngClass]="{'paypal-container__disabled' : !terms, 'paypal-container__loading' : isLoading}">
                    <div #paypal class="paypal-container__inner"></div>
                </div>
            </div>
        </div>
    </div>
</div>