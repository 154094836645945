<div class="top-bar">
    <div class="top-bar__widget">

    </div>
    <div class="top-bar__viewport">
        
    </div>
    <div class="top-bar__control">
        <button class="btn btn__transparent" (click)="cancel()">Abbrechen</button>
    </div>
</div>

<div class="gray-content">
    <div class="container">
        <div class="applications-list">
            <ul class="applications-list__list row">
                <li class="applications-list__item col-md-6">
                    <div class="applications-list__container">
                        <div class="applications-list__hover-link">
                            <button class="btn btn__blue btn__flex-center" (click)="connectInstagram()"><span class="material-icons">add</span> Widget hinzufügen</button>
                        </div>
                        <div class="applications-list__inner applications-list__inner--small-center">
                            <div class="applications-list__header">
                                <i class="fa fa-instagram applications-list__icon"></i>
                                <div>
                                    <h3>Instagram Feed</h3>
                                    <h2>Add posts from your Instagram account or by hashtag to your website</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="applications-list__item col-md-6">
                    <div class="applications-list__container">
                        <div class="applications-list__hover-link">
                            <button class="btn btn__blue btn__flex-center"><span class="material-icons">add</span> Widget hinzufügen</button>
                        </div>
                        <div class="applications-list__inner applications-list__inner--small-center">
                            <div class="applications-list__header">
                                <i class="fa fa-facebook applications-list__icon"></i>
                                <div>
                                    <h3>Facebook Feed</h3>
                                    <h2>Display posts, photos and videos from Facebook on your website</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>