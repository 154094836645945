import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AddToWebsiteComponent } from 'src/app/modal/add-to-website/add-to-website.component';
import { AuthService } from 'src/app/service/auth.service';
import { DataService } from 'src/app/service/data.service';
import { InstagramService } from 'src/app/service/instagram.service';
import { NavbarService } from 'src/app/service/navbar.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EditComponent implements OnInit {
  id: number;
  instagramApplications: any;
  instagramAccessToken: any;
  instagamHeader: any = {};
  instagramPosts: any = [];
  instagramPostsActive: any = [];
  postsLength: number;
  postsCounter: number;
  btnVisible: boolean = true;
  instagramConfig: any = {
    tabSwitch: {
      sources: true,
      layout: false,
      design: false,
      font: false
    },
    layout: {
      grid: true,
      list: false,
      masonry: false,
      customize: {
        columns: 2,
        rows: 3,
        gap: 30
      },
      bg: '#ffffff'
    },
    instagramFeedClass: {
      list: 'instagram-feed__list row',
      item: 'instagram-feed__item col-md-6'
    },
    cardLayout: {
      card1: true,
      card2: false,
      card3: false,
      bg: '#faf8f5'
    },
    toggles: {
      authorName: true,
      userHeader: false,
      date: true,
      description: true,
      actionBars: true,
      instagramLogo: true,
      loadMore: true
    },
    fonts: {
      size: {
        author: '15',
        authorHeader: '16',
        date: '13',
        description: '14',
      },
      color: {
        author: '#292929',
        authorHeader: '#292929',
        date: '#535353',
        description: '#292929'
      }
    }
  }
  color = 'primary';
  applicationName: string = '';

  private routeSubscription: Subscription;
  private userSubscription: Subscription;
  private instagramApplicationSubscription: Subscription;
  private instagramAccessTokenSubscription: Subscription;
  private instagramConfigSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private navService: NavbarService,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private instagramService: InstagramService,
    public dialog: MatDialog
  ) {
    this.navService.hide();
    this.navService.hideFooter();
  }

  ngOnInit(): void {
    if (this.authService.activeUser) {
      this.loadFunction(this.authService.activeUser);
    } else {
      this.userSubscription = this.authService.userChanged.subscribe(userData => {
        this.loadFunction(userData);
      });
    }
  }

  addtoWebsite() {
    const dialogRef = this.dialog.open(AddToWebsiteComponent, {
      data: {
        id: this.id
      },
      panelClass: 'add-to-website'
    });
  }

  loadFunction(item: any) {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.dataService.getInstagramApplication(this.id);
      this.dataService.getConfig(this.id);
      this.dataService.getApplication(item, this.id);
      this.instagramAccessTokenSubscription = this.dataService.instagramApplicationSubject.subscribe((data: any) => {
        this.instagramAccessToken = data.instagram_access_token;
        this.instagramService.getInstagramPostsUser(data.instagram_access_token, this.id);
        this.instagramApplicationSubscription = this.instagramService.instagramApplicationsSubject.subscribe((dataInstagram: any) => {
          this.instagamHeader = dataInstagram[0];
          this.instagramPostsActive = [];
          this.instagramApplications = dataInstagram[0];
          if (this.instagramApplications.posts && this.instagramApplications.posts.length != 0) this.loadPosts(this.instagramApplications.posts);
        });
      });
      this.instagramConfigSubscription = this.dataService.configSubject.subscribe((dataConfig: any) => {
        this.instagramConfig = dataConfig.config;
      });
      this.dataService.instagramApplicationDataSubject.subscribe((data: any) => {
        this.applicationName = data.application_name;
      });
    });
  }

  loadPosts(posts?: any) {
    if (posts) this.instagramPosts = posts;
    if (!posts) this.instagramPostsActive = [];
    let itemsVisible = this.instagramConfig?.layout?.customize?.rows;
    let itemsRow: number = this.instagramConfig?.layout?.customize?.columns;
    this.postsLength = this.instagramPosts.length;
    let calcVisible = itemsVisible * itemsRow;
    this.postsCounter = calcVisible;
    if ((this.postsLength - calcVisible) <= 0) {
      this.btnVisible = false
    } else {
      this.btnVisible = true
    }
    this.instagramPosts.forEach((element, elementIndex) => {
      if (elementIndex < calcVisible) this.instagramPostsActive.push(element);
    });
  }

  loadMore() {
    let itemsRow: number = this.instagramConfig.layout.customize.columns;
    this.postsCounter = (this.postsCounter * 1) + (itemsRow * 1);
    if ((this.postsLength - this.postsCounter) <= 0) this.btnVisible = false
    this.instagramPosts.forEach((element, elementIndex) => {
      if (elementIndex < this.postsCounter) {
        let check: number = this.instagramPostsActive.findIndex(x => x.id === element.id);
        if (check === -1) this.instagramPostsActive.push(element);
      }
    });
  }

  switchTab(item: any) {
    this.instagramConfig.tabSwitch = {
      sources: (item === 'sources') ? true : false,
      layout: (item === 'layout') ? true : false,
      design: (item === 'design') ? true : false,
      font: (item === 'font') ? true : false,
    }
  }

  switchLayout(item: any) {
    this.instagramConfig.layout.grid = (item === 'grid') ? true : false;
    this.instagramConfig.layout.list = (item === 'list') ? true : false;
    this.instagramConfig.layout.masonry = (item === 'masonry') ? true : false;
    let gap = (this.instagramConfig.layout.customize.gap) ? this.instagramConfig.layout.customize.gap : 0;
    if (item === 'grid') {
      this.getItemClass();
      this.instagramConfig.instagramFeedClass.list = 'instagram-feed__list row list-gap-' + gap;
    } else if (item === 'masonry') {
      let col = '';
      let colVar = this.instagramConfig.layout.customize.columns;
      if (colVar == 1) col = 'count-12';
      if (colVar == 2) col = 'count-6';
      if (colVar == 3) col = 'count-4';
      if (colVar == 4) col = 'count-3';
      if (colVar == 5) col = 'count-5';
      this.instagramConfig.instagramFeedClass.list = 'instagram-feed__list card-columns ' + col + ' gap-' + gap;
      this.instagramConfig.instagramFeedClass.item = 'instagram-feed__item card';
    } else {
      this.instagramConfig.instagramFeedClass.list = 'instagram-feed__list row list-gap-' + gap;
      this.instagramConfig.instagramFeedClass.item = 'instagram-feed__item col-md-12';
    }
  }

  switchColumns() {
    this.getItemClass();
    this.loadPosts();
  }

  switchGap() {
    this.getItemClass();
  }

  switchRows() {
    this.loadPosts();
  }

  getItemClass() {
    let col = '';
    let colVar = this.instagramConfig.layout.customize.columns;
    let gap = (this.instagramConfig.layout.customize.gap) ? this.instagramConfig.layout.customize.gap : 0;
    if (colVar == 1) col = '12';
    if (colVar == 2) col = '6';
    if (colVar == 3) col = '4';
    if (colVar == 4) col = '3';
    if (colVar == 5) col = '5';
    if (this.instagramConfig.layout.masonry) {
      this.instagramConfig.instagramFeedClass.list = 'instagram-feed__list card-columns count-' + col + ' gap-' + gap;
      col = 'card';
      this.instagramConfig.instagramFeedClass.item = 'instagram-feed__item ' + col;
    } else if (this.instagramConfig.layout.list) {
      this.instagramConfig.instagramFeedClass.list = 'instagram-feed__list row list-gap-' + gap;
      this.instagramConfig.instagramFeedClass.item = 'instagram-feed__item col-md-12 padd-' + gap;
    } else {
      this.instagramConfig.instagramFeedClass.list = 'instagram-feed__list row list-gap-' + gap;
      this.instagramConfig.instagramFeedClass.item = 'instagram-feed__item col-md-' + col + ' padd-' + gap;
    }
  }

  switchCardLayout(item: any) {
    this.instagramConfig.cardLayout.card1 = (item === 'card1') ? true : false;
    this.instagramConfig.cardLayout.card2 = (item === 'card2') ? true : false;
    this.instagramConfig.cardLayout.card3 = (item === 'card3') ? true : false;
  }

  changeLayoutBG(event: any) {
    this.instagramConfig.layout.bg = event;
  }

  changePostBG(event: any) {
    this.instagramConfig.cardLayout.bg = event;
  }

  changeColorAuthor(event: any) {
    this.instagramConfig.fonts.color.author = event;
  }

  changeColorAuthorHeader(event: any) {
    this.instagramConfig.fonts.color.authorHeader = event;
  }

  changeColorDate(event: any) {
    this.instagramConfig.fonts.color.date = event;
  }

  changeColorDescription(event: any) {
    this.instagramConfig.fonts.color.description = event;
  }

  cancel() {
    this.navService.show();
    this.navService.showFooter()
    this.router.navigate(['applications/social-feed']);
  }

  save() {
    this.dataService.updateInstagramApplication(this.id, this.instagramConfig, this.applicationName);
  }

  publish() {
    this.dataService.updateInstagramApplication(this.id, this.instagramConfig, this.applicationName);
    this.dataService.publishInstagramApplication(this.id, this.instagramConfig, this.instagramAccessToken);
  }

  ngOnDestroy() {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.instagramApplicationSubscription) this.instagramApplicationSubscription.unsubscribe();
    if (this.instagramAccessTokenSubscription) this.instagramAccessTokenSubscription.unsubscribe();
    if (this.instagramConfigSubscription) this.instagramConfigSubscription.unsubscribe();
  }
}
