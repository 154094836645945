import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/service/data.service';
import { InstagramApplication } from '../model/instagramApplication';
import { InstagramApplicationPosts } from '../model/instagramApplicationPosts';

@Injectable({
  providedIn: 'root'
})
export class InstagramService {
  instagramApplications: InstagramApplication[] = [];
  instagramApplicationsSubject = new Subject<any>();
  readonly instagramClientID = '933834547121574';
  readonly instagramClientSecret = 'f4b30fa6c93ed50815fa3637af9b359e';
  //readonly instagramConnectRedirectUri = 'https://localhost:4200/applications/social-feed/add';
  readonly instagramConnectRedirectUri = 'https://smart-widgets.de/applications/social-feed/add';
  readonly instagramGraph = 'https://graph.instagram.com';
  //readonly instagramPosts = '/me/media?fields=id,media_type,media_url,timestamp,caption,media_type,permalink,thumbnail_url,username';
  readonly instagramPosts = '/me/media?fields=id,media_url,timestamp,caption,media_type,permalink,thumbnail_url,username';
  readonly instagramUser = '/me?fields=id,username,media_count,account_type';

  constructor(
    private dataService: DataService,
    private httpClient: HttpClient
  ) { }

  connectInstagram() {
    window.location.href = 'https://api.instagram.com/oauth/authorize?client_id=' + this.instagramClientID + '&redirect_uri=' + this.instagramConnectRedirectUri + '&scope=user_profile,user_media&response_type=code';
  }

  generateInstagramAccessToken(token: string) {
    const body = new HttpParams()
      .set('client_id', this.instagramClientID)
      .set('client_secret', this.instagramClientSecret)
      .set('grant_type', 'authorization_code')
      .set('redirect_uri', this.instagramConnectRedirectUri)
      .set('code', token);

    this.httpClient.post('https://api.instagram.com/oauth/access_token', body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    })
      .subscribe((data: any) => {
        this.generateInstagramAccessTokenLong(data.access_token);
      });
  }

  generateInstagramAccessTokenLong(access_token: string) {
    const body = new HttpParams()
      .set('client_secret', 'f4b30fa6c93ed50815fa3637af9b359e')
      .set('access_token', access_token)
      .set('grant_type', 'ig_exchange_token');

    this.httpClient.get('https://graph.instagram.com/access_token?client_secret=' + this.instagramClientSecret + '&access_token=' + access_token + '&grant_type=ig_exchange_token')
      .subscribe((data: any) => {
        this.httpClient.get(this.instagramGraph + this.instagramUser + '&access_token=' + data.access_token)
          .subscribe((dataNew: any) => {
            this.dataService.addInstagramApplication(data.access_token, dataNew.id);
          });
      })
  }

  getInstagramPostsUser(access_token: any, editID?: number) {
    this.instagramApplications = [];
    this.httpClient.get(this.instagramGraph + this.instagramUser + '&access_token=' + access_token)
      .subscribe((data: any) => {
        this.instagramApplications.push(new InstagramApplication(
          {
            account_type: data.account_type,
            id: data.id,
            instagram_application_id: access_token.instagram_application_id,
            media_count: data.media_count,
            username: data.username,
            posts: []
          }
        ));
        this.instagramApplicationsSubject.next(this.instagramApplications);
        this.addInstagramPosts(access_token, data);
        //this.getExternInstagramPost(access_token);
      })
  }

  getExternInstagramPost(id: number) {
    this.httpClient.post(this.dataService.host + 'get_instagram_user_application/', { id: id }).pipe(
      map((data: any) => {
          return data.request;
      })
    )
    .subscribe((data: any) => {
      let value = (data?.counter) ? Math.round((data.counter / 200) * 100) : 0;
      if (data?.counter) {
        let application = this.instagramApplications.findIndex(x=> x.instagram_application_id === id);
        if (application !== -1) {
          this.instagramApplications[application].counter = data?.counter;
          this.instagramApplications[application].counterPercent = value;
          this.instagramApplicationsSubject.next(this.instagramApplications);
        }
      }
    })
  }

  addInstagramPosts(access_token: any, item: any) {
    this.httpClient.get(this.instagramGraph + this.instagramPosts + '&access_token=' + access_token).pipe(
      map((data: any) => {
        return data.data;
      })
    )
      .subscribe((data: any) => {
        let indexInstagramPosts: number = this.instagramApplications.findIndex(x => x.instagram_application_id === access_token.instagram_application_id);
        if (indexInstagramPosts !== -1) {
          data.forEach(element => {
            this.instagramApplications[indexInstagramPosts]?.posts.push(new InstagramApplicationPosts(element))
          });
        }
        this.instagramApplicationsSubject.next(this.instagramApplications);
        //this.addInstagramUserDetails(indexInstagramPosts, item);
      })
  }

  addInstagramUserDetails(index: number, data: any) {
    this.httpClient.get('https://www.instagram.com/' + data.username + '/?__a=1').pipe(
      map((data: any) => {
        return data.graphql.user
      })
    )
      .subscribe((data: any) => {
        this.instagramApplications[index].profile_pic_url = data.profile_pic_url;
        this.instagramApplications[index].profile_pic_url_hd = data.profile_pic_url_hd;
        this.instagramApplications[index].follow = data.edge_follow.count;
        this.instagramApplications[index].followed_by = data.edge_followed_by.count;
        this.instagramApplications[index].full_name = data.full_name;
      })
  }
}