import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/service/navbar.service';
import { UiService } from 'src/app/service/ui.service';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading = false;
  private loadingSubs: Subscription;

  constructor(
    private authService: AuthService,
    private uiService: UiService,
    private navService: NavbarService
  ) {
    this.navService.hideFooter();
  }

  ngOnInit(): void {
    this.loadingSubs = this.uiService.loadingStateChanged.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading
    });
    this.loginForm = new FormGroup({
      email: new FormControl('', {
        validators: [
          Validators.required, 
          Validators.email
        ]
      }),
      password: new FormControl('', { 
        validators: [
        Validators.required,
        Validators.minLength(6)
        ] 
      })
    });
  }

  onSubmit() {
    this.authService.signInWithEmail(this.loginForm.value.email, this.loginForm.value.password);
  }

  ngOnDestroy() {
    this.navService.showFooter();
    if (this.loadingSubs) {
      this.loadingSubs.unsubscribe();
    }
  }
}
