<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container hero-container-title__flex">
        <h1 class="h1__no-margin h1__light h1__small h1__white">YouTube Gallery</h1>
        <button class="btn btn__transparent btn__left-auto btn--no-padding" [routerLink]="['/applications']">Zurück</button>
    </div>
</div>

<div class="white-content">
    <div class="container">

    </div>
</div>