<h3>Add the widget to your website</h3>

<p>Copy and paste this code into desired place of your website (HTML editor, website template, theme, etc)</p>

<pre>
    {{ scriptText }}
    {{ divText }}
</pre>

<div class="btn-container">
    <button class="btn btn__green" mat-dialog-close>Cancel</button>
</div>