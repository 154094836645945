import { InstagramApplicationPosts } from '../model/instagramApplicationPosts';

export class InstagramApplication {
    account_type?: string;
    id?: number;
    instagram_application_id?: number;
    media_count?: number;
    username?: string;
    profile_pic_url?: string;
    profile_pic_url_hd?: string;
    follow?: number;
    followed_by?: number;
    full_name?: string;
    counter?: number;
    counterPercent? :number;
    posts?: InstagramApplicationPosts[]

    constructor(instagramApplication: InstagramApplication) {
        this.account_type = instagramApplication.account_type,
        this.id = instagramApplication.id,
        this.instagram_application_id = instagramApplication.instagram_application_id,
        this.media_count = instagramApplication.media_count,
        this.username = instagramApplication.username,
        this.profile_pic_url = instagramApplication.profile_pic_url,
        this.profile_pic_url_hd = instagramApplication.profile_pic_url_hd,
        this.follow = instagramApplication.follow,
        this.followed_by = instagramApplication.followed_by,
        this.full_name = instagramApplication.full_name,
        this.counter = instagramApplication.counter,
        this.counterPercent = instagramApplication.counterPercent,
        this.posts = instagramApplication.posts
    }
}