import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/service/data.service';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.less']
})
export class ApplicationsComponent implements OnInit {
  applications: [];
  userSubscription: Subscription;
  applicationsSubscription: Subscription;
  
  constructor(
    private dataService: DataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.authService.activeUser) {
      this.loadFunction(this.authService.activeUser);
    } else {
      this.userSubscription = this.authService.userChanged.subscribe(userData => {
        this.loadFunction(userData);
      });
    }
  }

  loadFunction(id: any) {
    this.dataService.getApplications(id);
    this.applicationsSubscription = this.dataService.applicationsSubject.subscribe((data: any) => {
      this.applications = data;
    });
  }

  scrollToApplications(el: HTMLElement) {
    el.scrollIntoView();
  }

  ngOnDestroy() {
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.applicationsSubscription) this.applicationsSubscription.unsubscribe();
  }
}