<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Kostenlos registrieren</h1>
    </div>
</div>

<div class="content-auth">
    <div class="container">
        <div class="row">
            <div class="col-md-6 m-auto">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="auth-form">
                    <h1 class="h1__margin-bottom-30 h1__small">Kostenlos registrieren</h1>
                    <mat-form-field appearance="fill" class="auth-form__full-width first">
                        <mat-label>E-Mail Adresse</mat-label>
                        <input type="email" matInput placeholder="E-Mail Adresse" formControlName="email" [value]="emailValue">
                        <mat-icon matSuffix>email</mat-icon>
                        <mat-error *ngIf="loginForm.controls.email.errors?.required">Ungültige oder fehlende E-Mail Adresse.</mat-error>
                        <mat-error *ngIf="loginForm.controls.email.errors?.email">Bitte geben Sie eine gültige E-Mail-Adresse ein.</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="auth-form__full-width">
                        <mat-label>Passwort</mat-label>
                        <input type="password" matInput placeholder="Passwort"
                            formControlName="password">
                        <mat-icon matSuffix>visibility_off</mat-icon>
                        <mat-error *ngIf="loginForm.controls.password.errors?.required">Ungültiges oder fehlendes Passwort.</mat-error>
                        <mat-error *ngIf="loginForm.controls.password.errors?.minlength?.requiredLength">Das Passwort muss aus mindestens 6 Zeichen bestehen.</mat-error>
                    </mat-form-field>
        
                    <button class="btn btn--auth" [disabled]="loginForm.invalid">Registrieren <div class="sk-chase" *ngIf="isLoading"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div></button>
                </form>
                <div class="content-auth__footer">
                    <p>Sie haben bereits ein Konto? <a routerLink="/login">Anmelden</a></p>
                </div>
            </div>
        </div>
    </div>
</div>