import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { NavbarService } from 'src/app/service/navbar.service';

export interface User {
  uid: string;
  email: string;
  emailVerified: boolean;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent implements OnInit {
  isUser: User;
  userSubscription: Subscription;

  constructor(
    private authService: AuthService,
    public nav: NavbarService
  ) { 
    this.userSubscription = this.authService.userChanged.subscribe(userData => {
      this.isUser = userData;
    });
  }

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}