import { YoutubeApplicationVideos } from '../model/youtubeApplicationVideos';

export class YoutubeApplication {
    title?: string;
    description?: string;
    customUrl?: string;
    thumbnail?: string;
    subscriberCount?: number;
    videoCount?: number;
    viewCount?: number;
    videos?: YoutubeApplicationVideos[]

    constructor(youtubeApplication: YoutubeApplication) {
        this.title = youtubeApplication.title,
        this.description = youtubeApplication.description,
        this.customUrl = youtubeApplication.customUrl,
        this.thumbnail = youtubeApplication.thumbnail,
        this.subscriberCount = youtubeApplication.subscriberCount,
        this.videoCount = youtubeApplication.videoCount,
        this.viewCount = youtubeApplication.viewCount,
        this.videos = youtubeApplication.videos
    }
}