<ul class="sidenav">
    <li class="sidenav__item">
        <a [routerLink]="['/profile']" class="sidenav__link" routerLinkActive="active">
            Mein Profil
        </a>
    </li>
    <li class="sidenav__item">
        <a [routerLink]="['/profile/billing']" class="sidenav__link" routerLinkActive="active">
            Meine Zahlungen
        </a>
    </li>
</ul>