import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from '../service/data.service';
import { NavbarService } from '../service/navbar.service';
import { UiService } from '../service/ui.service';

declare var paypal;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.less']
})
export class PaymentComponent implements OnInit {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;

  item: any;
  status: any;
  paymentStatus: any;
  color = 'primary';
  selectedPayment: any;
  paymentPlan: boolean = true;
  purchaseUnit: any = {};
  terms: boolean = false;
  isLoading: boolean = false;
  priceTable: any = {};
  runningTime: any = new Date();
  title: string = '';
  paymentStatusSubscription: Subscription;

  constructor(
    private router: Router,
    private uiService: UiService,
    private dataService: DataService,
    private navService: NavbarService
  ) {
    this.navService.hideFooter();
    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      this.item = navigation.extras.state.item ? navigation.extras.state.item : '';
      this.status = navigation.extras.state.status ? navigation.extras.state.status : '';
      this.title = navigation.extras.state.title ? navigation.extras.state.title : '';
      this.priceTable = this.dataService.getApplicationPrice(navigation.extras.state.item.application_type ? navigation.extras.state.item.application_type : '');
      if (this.status === 'extend') {
        if (this.item.descriptionPlan === 'Enterprise') {
          this.selectedPayment = this.priceTable.enterprise;
        }
        if (this.item.descriptionPlan === 'Pro') {
          this.selectedPayment = this.priceTable.pro;
        }
        if (this.item.descriptionPlan === 'Basic') {
          this.selectedPayment = this.priceTable.basic;
        }
        let price = (this.paymentPlan) ? this.selectedPayment.month : (this.selectedPayment.year * 12);
        this.purchaseUnit = {
          description: this.item.descriptionPlan,
          amount: {
            currency_code: 'EUR',
            value: price
          }
        }
        this.dataService.getPaymentStatus(this.item.application_id);
      }
      this.loadExternalScript("https://www.paypal.com/sdk/js?client-id=AfeEHHGkwhSzl97x9p2OzqPaMQGf9iWDEBWuHcJLRdjRlMCulbAIHw9eN-KrKo43ZBmwWvGcRPSPpeBW&currency=EUR").then(() => {
          paypal
            .Buttons({
              createOrder: (data, actions) => {
                this.isLoading = true;
                return actions.order.create({
                  purchase_units: [
                    {
                      description: this.purchaseUnit.description,
                      amount: {
                        currency_code: this.purchaseUnit.amount.currency_code,
                        value: (!this.paymentPlan) ? this.selectedPayment.month : (this.selectedPayment.year * 12)
                      },
                    }
                  ]
                });
              },
              onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                let payment = {
                  id: order.id,
                  create_time: order.create_time,
                  update_time: order.update_time,
                  old_time: (this.status === 'extend') ? this.paymentStatus : '',
                  status: order.status,
                  application_id: this.item.application_id,
                  description: this.purchaseUnit.description,
                  paymentPlan: (this.paymentPlan) ? 'Yearly' : 'Monthly',
                  value: (!this.paymentPlan) ? this.selectedPayment.month : (this.selectedPayment.year * 12)
                }
                if (order.status === 'COMPLETED') this.dataService.addPayment(payment);
                this.isLoading = false;
                this.router.navigate(['/profile/billing/' + order.id]);
              },
              onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);
                this.isLoading = false;
              },
              onError: err => {
                this.isLoading = false;
                this.uiService.showSnackbar(err, null, 10000);
              }
            })
            .render(this.paypalElement.nativeElement)
        });
    } else {
      this.router.navigate(['applications']);
    }
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement('script')
      scriptElement.src = scriptUrl
      scriptElement.onload = resolve
      document.body.appendChild(scriptElement)
    });
  }

  ngOnInit(): void {
    this.paymentStatusSubscription = this.dataService.paymentStatus.subscribe((data: any) => {
      this.paymentStatus = data.activeUntil;
      this.runningTime = data.activeUntil;
      let date = new Date(this.paymentStatus);
      this.runningTime = (this.paymentPlan) ? date.setMonth(date.getMonth() + 12) : date.setMonth(date.getMonth() + 1);
    });
  }

  changePaymentPlan() {
    this.paymentPlan = !this.paymentPlan;
    let date: Date = new Date();
    if (this.paymentStatus) date = new Date(this.paymentStatus);
    this.runningTime = (this.paymentPlan) ? date.setMonth(date.getMonth() + 12) : date.setMonth(date.getMonth() + 1);
  }

  resetPlan() {
    this.purchaseUnit = {};
  }

  payment(item: any) {
    let date: Date = new Date();
    this.runningTime = (this.paymentPlan) ? date.setMonth(date.getMonth() + 12) : date.setMonth(date.getMonth() + 1);
    if (item.packet === 'Enterprise') {
      this.selectedPayment = this.priceTable.enterprise;
    }
    if (item.packet === 'Pro') {
      this.selectedPayment = this.priceTable.pro;
    }
    if (item.packet === 'Basic') {
      this.selectedPayment = this.priceTable.basic;
    }
    let price = (this.paymentPlan) ? this.selectedPayment.month : (this.selectedPayment.year * 12);
    this.purchaseUnit = {
      description: item.packet,
      amount: {
        currency_code: 'EUR',
        value: price
      }
    }
  }

  ngOnDestroy() {
    this.navService.showFooter();
    if (this.paymentStatusSubscription) this.paymentStatusSubscription.unsubscribe();
  }
}
