import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { DataService } from 'src/app/service/data.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { YoutubeService } from 'src/app/service/youtube.service';

@Component({
  selector: 'app-edit-youtube',
  templateUrl: './edit-youtube.component.html',
  styleUrls: ['./edit-youtube.component.less']
})
export class EditYoutubeComponent implements OnInit {
  id: number;
  youtubeConfig: any = {
    tabSwitch: {
      sources: true,
      layout: false,
      design: false,
      font: false
    },
  };
  applicationName: string = '';
  youtubeApplication: any = {
    google_api: '',
    youtube_token: '',
    youtube_url: ''
  };
  youtubePostsActive: any = [];

  private routeSubscription: Subscription;
  private userSubscription: Subscription;
  private youtubeTokenSubscription: Subscription;
  private youtubeApplicationSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private navService: NavbarService,
    private router: Router,
    private authService: AuthService,
    private dataService: DataService,
    private youtubeService: YoutubeService
  ) {
    this.navService.hide();
    this.navService.hideFooter();
  }

  ngOnInit(): void {
    if (this.authService.activeUser) {
      this.loadFunction(this.authService.activeUser);
    } else {
      this.userSubscription = this.authService.userChanged.subscribe(userData => {
        this.loadFunction(userData);
      });
    }
  }

  loadFunction(item: any) {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.dataService.getYoutubeppApplication(this.id);
      this.dataService.getApplication(item, this.id);
      this.youtubeTokenSubscription = this.dataService.youtubeApplicationSubject.subscribe((data: any) => {
        this.youtubeApplication = data;
        this.getChannels();
      });
      this.dataService.instagramApplicationDataSubject.subscribe((data: any) => {
        this.applicationName = data.application_name;

      });
      this.youtubeApplicationSubscription = this.youtubeService.youtubeApplicationsSubject.subscribe((data: any) => {
        this.youtubePostsActive = data.videos;
      });
    });
  }

  getChannels() {
    if (this.youtubeApplication.google_api && this.youtubeApplication.youtube_url && this.youtubeApplication) {
      this.youtubeService.getChannels(this.youtubeApplication);
    }
  }

  switchTab(item: any) {
    this.youtubeConfig.tabSwitch = {
      sources: (item === 'sources') ? true : false,
      layout: (item === 'layout') ? true : false,
      design: (item === 'design') ? true : false,
      font: (item === 'font') ? true : false,
    }
  }

  save() {
    this.dataService.updateYoutubeApplication(this.id, this.applicationName, this.youtubeApplication);
  }

  getChannelFromUrl(url: string) {
    var pattern = new RegExp('^(https?:\/\/)?(www\.)?youtube\.com/(user/)?([a-z\-_0-9]+)/?([\?#]?.*)', 'i');
    var matches = url.match(pattern);
    if(matches) {
      return matches[4];
    }
    return url;
  }

  changeUrl() {
    this.youtubeApplication.youtube_token = this.getChannelFromUrl(this.youtubeApplication.youtube_url);
    this.getChannels();
  }

  changeKey() {
    this.getChannels();
  }

  addtoWebsite() {

  }

  cancel() {
    this.navService.show();
    this.navService.showFooter()
    this.router.navigate(['applications/youtube']);
  }

  ngOnDestroy() {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.youtubeTokenSubscription) this.youtubeTokenSubscription.unsubscribe();
    if (this.youtubeApplicationSubscription) this.youtubeApplicationSubscription.unsubscribe();
  }
}