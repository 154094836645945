import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationsComponent } from './applications/applications.component';
import { AddComponent } from './applications/social-feed/add/add.component';
import { SocialFeedComponent } from './applications/social-feed/social-feed.component';
import { LoginComponent } from './auth/login/login.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { EditComponent } from './applications/social-feed/instagram/edit/edit.component';
import { ProfileComponent } from './profile/profile.component';
import { PaymentComponent } from './payment/payment.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PROFILE_ROUTES } from './profile/profile.routes';
import { ImpressumComponent } from './dashboard/impressum/impressum.component';
import { DatenschutzComponent } from './dashboard/datenschutz/datenschutz.component';
import { YoutubeComponent } from './applications/youtube/youtube.component';
import { AddYoutubeComponent } from './applications/youtube/add-youtube/add-youtube.component';
import { EditYoutubeComponent } from './applications/youtube/edit-youtube/edit-youtube.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectSignedInToApplications = () => redirectLoggedInTo(['applications']);

const routes: Routes = [
  {path: '', component: DashboardComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectSignedInToApplications }},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'datenschutz', component: DatenschutzComponent},
  {path: 'login', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectSignedInToApplications }},
  {path: 'register', component: RegistrationComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectSignedInToApplications }},
  {path: 'register/:email', component: RegistrationComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectSignedInToApplications }},
  {path: 'reset-password', component: ResetPasswordComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectSignedInToApplications }},
  {path: 'applications', component: ApplicationsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'applications/social-feed', component: SocialFeedComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'applications/social-feed/add', component: AddComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'applications/social-feed/instagram/add/:id', component: EditComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'applications/youtube', component: YoutubeComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'applications/youtube/add', component: AddYoutubeComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'applications/youtube/add/:id', component: EditYoutubeComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path: 'profile', component: ProfileComponent, children: PROFILE_ROUTES},
  {path: 'payment', component: PaymentComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
