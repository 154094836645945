import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-to-website',
  templateUrl: './add-to-website.component.html',
  styleUrls: ['./add-to-website.component.less']
})
export class AddToWebsiteComponent implements OnInit {
  id: number;
  scriptText: string = '';
  divText: string = '<script src="http://p565722.mittwaldserver.info/rest/v1/app.js"></script>';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.id = data.id;
  }

  ngOnInit(): void {
    this.scriptText = '<share-later id="html-social-stream" data-id="' + this.id + '"></share-later>';
  }

}
