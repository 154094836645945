import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../service/data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  email: string = '';
  paymentPlan: boolean = true;
  color = 'primary';
  priceTable: any = {};

  constructor(
    private router: Router,
    private dataService: DataService
  ) { 
    this.priceTable = this.dataService.getApplicationPrice('Instagram');
  }

  ngOnInit(): void {
  }

  conversion() {
    if (this.email) {
      this.router.navigate(['/register/' + this.email]);
    } else {
      this.router.navigate(['/register/']);
    }
  }
}
