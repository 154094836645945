export class InstagramApplicationPosts {
    caption?: string;
    id?: string;
    media_type?: string;
    media_url?: string;
    permalink?: string;
    timestamp?: string;
    username?: string;

    constructor(instagramApplicationPosts: InstagramApplicationPosts) {
        this.caption = instagramApplicationPosts.caption,
        this.id = instagramApplicationPosts.id,
        this.media_type = instagramApplicationPosts.media_type,
        this.media_url = instagramApplicationPosts.media_url,
        this.permalink = instagramApplicationPosts.permalink,
        this.timestamp = instagramApplicationPosts.timestamp,
        this.username = instagramApplicationPosts.username
    }
}