<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Impressum</h1>
    </div>
</div>
<div class="white-content">
    <div class="container">
        <div class="impressum">
            <h2 class="h2 h2__small">Angaben gemäß § 5 TMG:</h2>

            <h3 class="h3 h3__small h3__bold">VERANTWORTUNG KONTAKT:</h3>

            <p>Christian Stein, Christian Schäfer - UltraPixel GbR</p>

            <p>vertreten durch Herrn Christian Stein und Herrn Christian Schäfer</p>

            <p>UltraPixel<br>
                Zur Höhe 47a<br>
                58091 Hagen<br>
                +49 (2331) 1885252<br>
                <a href="mailto:info@ultrapixel.de" title="Email an Ultrapixel senden">info@ultrapixel.de</a></p>

            <p>&nbsp;</p>

            <p
                style="margin-bottom: 5px; font-family: sans-serif; font-size: 15px; line-height: 18px;">
                Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="http://www.ec.europa.eu/consumers/odr"
                    target="_blank">www.ec.europa.eu/consumers/odr</a></p>

        </div>
    </div>
</div>