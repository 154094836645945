<div class="hero-container small"></div>
<div class="hero-container-title">
    <div class="container">
        <h1 class="h1__no-margin h1__light h1__small h1__white">Datenschutzerklärung</h1>
    </div>
</div>
<div class="white-content">
    <div class="container">
        <h2 class="h2 h2__small">Datenschutzerklärung | smart-widgets.de</h2>
        <p>Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.</p>
        <p>"Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.</p>
        <h3 class="h3 h3__small h3__bold">Server-Logfiles</h3>
        <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Es werden bei jedem Zugriff auf unsere Website Nutzungsdaten durch Ihren Internetbrowser übermittelt und in Protokolldaten (Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und der anfragende Provider. Diese Daten dienen ausschließlich der Gewährleistung eines störungsfreien Betriebs unserer Website und zur Verbesserung unseres Angebotes. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht möglich.</p>
        <h3 class="h3 h3__small h3__bold">Erhebung und Verarbeitung bei Nutzung des Kontaktformulars</h3>
        <p>Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme. Mit Absenden Ihrer Nachricht willigen Sie in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung.</p>
        <p>Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
        <h3 class="h3 h3__small h3__bold">Kundenkonto</h3>
        <p>Bei der Eröffnung eines Kundenkontos erheben wir Ihre personenbezogenen Daten in dem dort angegeben Umfang. Die Datenverarbeitung dient dem Zweck, Ihr Einkaufserlebnis zu verbessern und die Bestellabwicklung zu vereinfachen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihr Kundenkonto wird anschließend gelöscht.</p>
        <h3 class="h3 h3__small h3__bold">Datenerhebung bei Verfassung eines Kommentars</h3>
        <p>Bei der Kommentierung eines Artikels oder eines Beitrages erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Kommentartext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Verarbeitung dient dem Zweck, eine Kommentierung zu ermöglichen und Kommentare anzuzeigen. Mit Absenden des Kommentars willigen Sie in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre personenbezogenen Daten werden anschließend gelöscht.</p>
        <p>Darüber hinaus wird bei Abgabe des Kommentars Ihre IP-Adresse gespeichert zu dem Zweck, einen Missbrauch der Kommentarfunktion zu verhindern und die Sicherheit unserer informationstechnischen Systeme sicherzustellen. Mit Absenden des Kommentars willigen Sie in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre IP-Adresse wird anschließend gelöscht.</p>
        <h3 class="h3 h3__small h3__bold">Cookies</h3>
        <p>Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. Wir setzen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
        <p>Wir verwenden auf unserer Website darüber hinaus Cookies zu dem Zweck, eine Analyse des Surfverhaltens unserer Seitenbesucher zu ermöglichen.</p>
        <p>Die Verarbeitung erfolgt auf Grundlage des § 15 (3) TMG sowie Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an den oben genannten Zwecken.</p>
        <p>Die auf diese Weise von Ihnen erhobenen Daten werden durch technische Vorkehrungen pseudonymisiert. Eine Zuordnung der Daten zu Ihrer Person ist daher nicht mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten von Ihnen gespeichert.</p>
        <p>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
        <p>Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren) können:</p>
        <p class="p p__no-margin">Chrome Browser: <a target="_blank" href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank">https://support.google.com/accounts/answer/61416?h...</a></p>
        <p class="p p__no-margin">Internet Explorer: <a target="_blank" href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/de-de/help/17442/win...</a></p>
        <p class="p p__no-margin">Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" target="_blank">https://support.mozilla.org/de/kb/cookies-erlauben...</a></p>
        <p>Safari: <a href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">https://support.apple.com/de-de/guide/safari/manag...</a></p>
        <h3 class="h3 h3__small h3__bold">Nutzung von Google Analytics</h3>
        <p>Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
        <p>Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch die Cookies erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Ihre Daten werden gegebenenfalls in die USA übermittelt. Für Datenübermittlungen in die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission vorhanden. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an der bedarfsgerechten und zielgerichteten Gestaltung der Website. Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
        <p>Sie können dazu die Speicherung der Cookies durch die Auswahl entsprechender technischer Einstellungen Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können des Weiteren die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren [<a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>]. Um die Erfassung durch Google Analytics geräteübergreifend zu verhindern können Sie einen Opt-Out-Cookie setzen. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website. Sie müssen das Opt-Out auf allen genutzten Systemen und Geräten durchführen, damit dies umfassend wirkt. Wenn Sie hier klicken, wird das Opt-Out-Cookie gesetzt: Google Analytics deaktivieren.</p>
        <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://www.google.com/analytics/terms/de.html" target="_blank">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://www.google.de/intl/de/policies/" target="_blank">https://www.google.de/intl/de/policies/</a>.</p>
        <h3 class="h3 h3__small h3__bold">Verwendung von YouTube</h3>
        <p>Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der YouTube LLC. (901 Cherry Ave., San Bruno, CA 94066, USA; „YouTube“). YouTube ist ein mit der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”) verbundenes Unternehmen.</p>
        <p>Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die Option „Erweiterter Datenschutzmodus“ aktiviert. Dadurch werden von YouTube keine Informationen über die Besucher der Website gespeichert. Erst wenn Sie sich ein Video ansehen, werden Informationen darüber an YouTube übermittelt und dort gespeichert.</p>
        <p>Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, über Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den Datenschutzhinweisen von YouTube (<a href="https://www.youtube.com/t/privacy" target="_blank">https://www.youtube.com/t/privacy</a>).</p>
        <h3 class="h3 h3__small h3__bold">Verwendung von GoogleMaps</h3>
        <p>Wir verwenden auf unserer Website die Funktion zur Einbettung von GoogleMaps-Karten der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”).</p>
        <p>Die Funktion ermöglicht die visuelle Darstellung von geographischen Informationen und interaktiven Landkarten.</p>
        <p>Dabei werden von Google bei Aufrufen der Seiten, in die GoogleMaps-Karten eingebunden sich, auch Daten der Besucher der Seiten erhoben, verarbeitet und genutzt. Nähere Informationen zur Erhebung und Nutzung der Daten durch Google finden Sie in den Datenschutzhinweisen von Google unter <a href="https://www.google.com/privacypolicy.html" target="_blank">https://www.google.com/privacypolicy.html</a>. Dort haben Sie auch im Datenschutzcenter die Möglichkeit Ihre Einstellungen zu verändern, so dass Sie Ihre von Google verarbeiteten Daten verwalten und schützen können.</p>
        <p>Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Für Datenübermittlungen in die USA ist ein Angemessenheitsbeschluss der Europäischen Kommission vorhanden.</p>
        <p>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu widersprechen.</p>
        <p>Dazu müssen Sie die Anwendung JavaScript in Ihrem Browser ausschalten. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website, wie bspw. die interaktive Kartenanzeige, vollumfänglich werden nutzen können.</p>
        <h3 class="h3 h3__small h3__bold">Dauer der Speicherung</h3>
        <p>Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</p>
        <p>Rechte der betroffenen Person</p>
        <p>Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.</p>
        <p>Außerdem steht Ihnen nach Art. 21 (1) DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 (1) f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.</p>
        <p>Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten finden Sie in unserem Impressum.</p>
        <h3 class="h3 h3__small h3__bold">Beschwerderecht bei der Aufsichtsbehörde</h3>
        <p>Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.</p>
    </div>
</div>