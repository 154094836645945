import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarService } from 'src/app/service/navbar.service';
import { InstagramService } from 'src/app/service/instagram.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.less']
})
export class AddComponent implements OnInit {
  private routeSubs: Subscription;
  userSubscription: Subscription;
  applicationSelected: any;

  constructor(
    private navService: NavbarService,
    private instagramService: InstagramService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.navService.hide();
    this.navService.hideFooter();
    const navigation = this.router.getCurrentNavigation();
    this.applicationSelected = navigation.extras.state ? navigation.extras.state.application : '';
    if (this.applicationSelected === 'Instagram') this.instagramService.connectInstagram();
  }

  ngOnInit(): void {
    if (this.authService.activeUser) {
      this.loadFunction();
    } else {
      this.userSubscription = this.authService.userChanged.subscribe(userData => {
        this.loadFunction();
      });
    }
  }

  loadFunction() {
    this.routeSubs = this.activatedRoute.queryParams.subscribe(params => {
      let code = params['code'];
      if (code ) this.instagramService.generateInstagramAccessToken(code);
    });
  }

  cancel() {
    this.navService.show();
    this.navService.showFooter();
    this.router.navigate(['applications']);
  }

  connectInstagram() {
    this.instagramService.connectInstagram();
  }

  ngOnDestroy() {
    if (this.routeSubs) this.routeSubs.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }
}