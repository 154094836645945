import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/service/auth.service';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UiService } from './ui.service';
import { Application } from '../model/application';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  readonly host = 'https://p565722.mittwaldserver.info/api/';
  readonly instagramApplication = this.host + 'instagram/instagram_application/';
  readonly externAddApplication = this.host + 'add_application/';
  readonly externApplicationData = this.host + 'application_data/';
  readonly externApplicationPayment = this.host + 'add_payment/';
  readonly externApplicationGetPayment = this.host + 'get_payment/';

  applications: Application[] = [];
  applicationsSubject = new Subject<any>();
  youtubeApplicationSubject = new Subject<[]>();
  instagramApplicationSubject = new Subject<[]>();
  instagramApplicationDataSubject = new Subject<[]>();
  instagramApplications: any = [];
  instagramApplicationsSubject = new Subject<[]>();
  accoountUserInfo = new Subject();
  configSubject = new Subject<[]>();
  billingInfo = new Subject();
  myPayments = new Subject();
  paymentStatus = new Subject();

  constructor(
    private authService: AuthService,
    public afs: AngularFirestore,
    private httpClient: HttpClient,
    private router: Router,
    public uiService: UiService
  ) { }

  getApplications(user: any) {
    this.applications = [];
    this.afs.collection(user.uid).snapshotChanges().pipe(
      map(docArray => {
        return docArray.map(doc => {
          return {
            application_id: doc.payload.doc.id,
            application_name: doc.payload.doc.data()['application_name'],
            application_type: doc.payload.doc.data()['application_type']
          };
        });
      })
    )
      .subscribe((data: any) => {
        this.applications = data;
        this.applicationsSubject.next(data);
        data.forEach(element => {
          this.httpClient.post(this.externApplicationData, { id: element.application_id }).pipe(
            map((dataUser: any) => {
              return dataUser.request;
            })
          ).subscribe((dataUser: any) => {
            let value = (dataUser?.counter) ? Math.round((dataUser.counter / 200) * 100) : 0;
            let application = this.applications.findIndex(x => x.application_id === element.application_id);
            if (application !== -1) {
              this.applications[application].counter = dataUser?.counter;
              this.applications[application].counterPercent = value;
              this.applications[application].descriptionPlan = dataUser?.descriptionPlan;
              if (dataUser?.activeUntil) this.applications[application].activeUntil = dataUser?.activeUntil;
              this.applicationsSubject.next(this.applications);
            }
          });
        });
      });
  }

  removeAppliaction(item: any) {
    let application = this.applications.findIndex(x => x.application_id === item.application_id);
    if (application !== -1) {
      let user = this.authService.activeUser;
      this.applications.splice(application, 1);
      if (item.application_type === 'Instagram') {
        this.afs.collection('instagramToken').doc(item.application_id).delete();
      }
      if (item.application_type === 'Youtube') {
        this.afs.collection('youtubeToken').doc(item.application_id).delete();
      }
      this.afs.collection(user.uid).doc(item.application_id).delete();
      this.afs.collection('config').doc(item.application_id).delete();
      this.applicationsSubject.next(this.applications);
    }
  }

  getApplication(user: any, id: any) {
    this.afs.collection(user.uid).doc(id).snapshotChanges().pipe(
      map(changes => {
        const data = changes.payload.data();
        if (data !== undefined) {
          return {
            application_name: data['application_name']
          };
        } else {
          return {
            application_name: ''
          };
        }
      })
    )
      .subscribe((data: any) => {
        this.instagramApplicationDataSubject.next(data);
      })
  }

  getPayment(user: any) {
    this.uiService.loadingStateChanged.next(true);
    this.httpClient.post(this.externApplicationGetPayment, { uid: user.uid }).pipe(
      map((data: any) => {
        return data.request;
      })
    )
    .subscribe((data: any) => {
      this.uiService.loadingStateChanged.next(false);
      this.myPayments.next(data);
    });
  }

  addPayment(data: any) {
    let user = this.authService.activeUser;
    this.afs.collection('payments').doc(data.id).set({
      application_id: data.application_id,
      create_time: data.create_time,
      description: data.description,
      id: data.id,
      user_id: user.uid,
      paymentPlan: data.paymentPlan,
      status: data.status,
      update_time: data.update_time,
      value: data.value
    })
      .then(() => {

      }).catch((error) => {
        this.uiService.showSnackbar(error.message, null, 10000);
      })
    this.httpClient.post(this.externApplicationPayment, { user: user, data: data }).pipe(
      map((dataUser: any) => {
        return dataUser;
      })
    )
      .subscribe((dataUser: any) => {
        console.log(dataUser);
      });
  }

  addYoutubeApplication() {
    let dateTime: number = new Date().getTime();
    let dateTimeString: string = dateTime.toString();
    let user = this.authService.activeUser;
    this.afs.collection(user.uid).doc(dateTimeString).set({
      application_type: 'Youtube',
      application_name: 'Untitled'
    });

    this.afs.collection('config').doc(dateTimeString).set({
      config: {}
    });

    this.afs.collection('youtubeToken').doc(dateTimeString).set({
      youtube_token: 'latenight',
      youtube_url: 'https://www.youtube.com/user/latenight',
      google_api: ''
    });

    this.router.navigate(['applications/youtube/add', dateTime]);
    this.addExternApplication('', dateTimeString, 'Youtube', user.uid);
  }

  addInstagramApplication(access_token: string, user_id: number) {
    let dateTime: number = new Date().getTime();
    let dateTimeString: string = dateTime.toString();
    let user = this.authService.activeUser;
    this.afs.collection(user.uid).doc(dateTimeString).set({
      application_type: 'Instagram',
      application_name: 'Untitled'
    });

    this.afs.collection('instagramToken').doc(dateTimeString).set({
      instagram_access_token: access_token,
      instagram_id: user_id
    });

    this.afs.collection('config').doc(dateTimeString).set({
      config: {
        tabSwitch: {
          sources: true,
          layout: false,
          design: false,
          font: false
        },
        layout: {
          grid: true,
          list: false,
          masonry: false,
          customize: {
            columns: 2,
            rows: 3,
            gap: 30
          },
          bg: '#ffffff'
        },
        instagramFeedClass: {
          list: 'instagram-feed__list row',
          item: 'instagram-feed__item col-md-6'
        },
        cardLayout: {
          card1: true,
          card2: false,
          card3: false,
          bg: '#faf8f5'
        },
        toggles: {
          authorName: true,
          userHeader: false,
          date: true,
          description: true,
          actionBars: true,
          instagramLogo: true,
          loadMore: true
        },
        fonts: {
          size: {
            author: '15',
            authorHeader: '16',
            date: '13',
            description: '14',
          },
          color: {
            author: '#292929',
            authorHeader: '#292929',
            date: '#535353',
            description: '#292929'
          }
        }
      }
    });
    this.router.navigate(['applications/social-feed/instagram/add', dateTime]);

    this.addExternApplication(access_token, dateTimeString, 'Instagram', user.uid);
  }

  addExternApplication(access_token: string, dateTimeString: string, application: string, uid: string) {
    let data = {
      application_type: application,
      application_id: dateTimeString,
      access_token: access_token,
      uid: uid
    }

    this.httpClient.post(this.externAddApplication, { status: 'add', data: data }).pipe(
      map((data: any) => {
        return data;
      })
    )
      .subscribe((data: any) => {
      });
  }

  getInstagramApplication(id: any) {
    this.afs.collection('instagramToken').doc(id).snapshotChanges().pipe(
      map(changes => {
        const data = changes.payload.data();
        if (data !== undefined) {
          return {
            instagram_access_token: data['instagram_access_token'],
            instagram_id: data['instagram_id']
          };
        } else {
          return {
            instagram_access_token: '',
            instagram_id: ''
          };
        }
      })
    )
      .subscribe((data: any) => {
        this.instagramApplicationSubject.next(data);
      })
  }

  getYoutubeppApplication(id: any) {
    this.afs.collection('youtubeToken').doc(id).snapshotChanges().pipe(
      map(changes => {
        const data = changes.payload.data();
        if (data !== undefined) {
          return {
            youtube_token: data['youtube_token'],
            youtube_url: data['youtube_url'],
            google_api: data['google_api']
          };
        } else {
          return {
            instagram_access_token: '',
            instagram_id: ''
          };
        }
      })
    )
      .subscribe((data: any) => {
        this.youtubeApplicationSubject.next(data);
      })
  }

  getConfig(id: any) {
    this.afs.collection('config').doc(id).snapshotChanges().pipe(
      map(changes => {
        const data = changes.payload.data();
        if (data !== undefined) {
          return {
            config: data['config']
          };
        } else {
          return {
            config: ''
          };
        }
      })
    )
      .subscribe((data: any) => {
        this.configSubject.next(data);
      })
  }

  updateInstagramApplication(post: any, config: any, applicationName: string) {
    let user = this.authService.activeUser;
    this.uiService.loadingStateChanged.next(true);
    this.afs.doc('config' + '/' + post).update({
      config: config
    }).then(() => {
      this.afs.doc(user.uid + '/' + post).update({
        application_name: applicationName
      }).then(() => {
        this.uiService.loadingStateChanged.next(false);
        this.uiService.showSnackbar('Changes have been saved.', null, 3000);
      }).catch((error) => {
        this.uiService.loadingStateChanged.next(false);
        this.uiService.showSnackbar(error.message, null, 10000);
      })
    }).catch((error) => {
      this.uiService.loadingStateChanged.next(false);
      this.uiService.showSnackbar(error.message, null, 10000);
    })
  }

  updateYoutubeApplication(post: any, applicationName: string, youtubeApplication: any) {
    let user = this.authService.activeUser;
    this.afs.doc('youtubeToken/' + post).update({
      youtube_token: youtubeApplication.youtube_token,
      youtube_url: youtubeApplication.youtube_url,
      google_api: youtubeApplication.google_api
    });
    this.afs.doc(user.uid + '/' + post).update({
      application_name: applicationName
    });
    console.log(user);
    console.log(post);
    console.log(applicationName);
    console.log(youtubeApplication);
  }

  publishInstagramApplication(post: any, config: any, access_token: any) {
    this.uiService.loadingStateChanged.next(true);
    this.httpClient.post(this.instagramApplication, { post: post, config: config, access_token: access_token }).pipe(
      map((data: any) => {
        return data;
      })
    )
      .subscribe((data: any) => {
        this.uiService.loadingStateChanged.next(false);
        setTimeout(() => {
          this.uiService.showSnackbar('Changes have been published.', null, 3000);
        }, 3000);
      });
  }

  editUserInfo(data: any) {
    let user = this.authService.activeUser;
    return this.afs.collection('user').doc(user.uid).set({
      first_name: data.value.firstName,
      last_name: data.value.lastName,
      phone: data.value.phone,
      website: data.value.website
    });
  }

  getUserInfo(user: any) {
    this.afs.collection('user').doc(user.uid).snapshotChanges().pipe(
      map(changes => {
        const data = changes.payload.data();
        return data;
      })
    )
      .subscribe((data: any) => {
        this.accoountUserInfo.next(data);
      })
  }

  getBillingInfo(id: any, uid: any) {
    this.afs.collection('payments').doc(id).snapshotChanges().pipe(
      map(changes => {
        const data = changes.payload.data();
        return data;
      })
    )
      .subscribe((data: any) => {
        if (data && data.user_id === uid) {
          this.billingInfo.next(data);
        }
      })
  }

  getApplicationPrice(application: string) {
    let priceTable = {
      lite: {
        month: 0,
        year: 0
      },
      basic: {
        month: 0,
        year: 0
      },
      pro: {
        month: 0,
        year: 0
      },
      enterprise: {
        month: 0,
        year: 0
      }
    };
    if (application === 'Instagram') {
      priceTable.lite.month = 0;
      priceTable.lite.year = 0;
      priceTable.basic.month = 12;
      priceTable.basic.year = 10;
      priceTable.pro.month = 24;
      priceTable.pro.year = 20;
      priceTable.enterprise.month = 48;
      priceTable.enterprise.year = 40;
    }
    return priceTable;
  }

  getPaymentStatus(id: any) {
    this.httpClient.post(this.externApplicationData, { id: id }).pipe(
      map((data: any) => {
        return data.request;
      })
    ).subscribe((data: any) => {
      this.paymentStatus.next(data);
    });
  }
}