<div class="hero-container hero-container--auto hero-container--flex">
    <div class="hero-container__inner">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="h1 h1__white">Smart Widgets for Your Website</h1>
                </div>
                <div class="col-md-8 m-auto">
                    <p class="hero-container__text">- without coding -</p>
                </div>
                <div class="col-md-6 m-auto">
                    <div class="hero-container__form">
                        <input type="text" class="hero-container__input" placeholder="Ihre E-Mail Adresse" [(ngModel)]="email">
                        <button class="btn hero-container__button" (click)="conversion()">Start</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="hero-container__img-container">
            <img class="hero-container__img" src="../../../assets/img/embed-instagram-feed-widget.png">
        </div>
    </div>
</div>


<div class="white-content">
    <div class="container">
        <div class="payment-plan">
            <div class="payment-plan__toggle">
                <div [className]="paymentPlan ? 'payment-plan__toggle-select' : 'payment-plan__toggle-select--active'">
                    Monatlich</div>
                <div class="payment-plan__toggle-btn">
                    <mat-slide-toggle class="example-margin" [color]="color" [(ngModel)]="paymentPlan">
                    </mat-slide-toggle>
                </div>
                <div [className]="!paymentPlan ? 'payment-plan__toggle-select' : 'payment-plan__toggle-select--active'">
                    Jährlich (spare 17%)</div>
            </div>
            <ul class="payment-plan__list">
                <li class="payment-plan__item payment-plan__item--first">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Enterprise</h3>
                            <p>For websites with heavy traffic and client work</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price" *ngIf="paymentPlan">{{ priceTable?.enterprise?.year }}</span>
                                <span class="payment-plan__body-price" *ngIf="!paymentPlan">{{ priceTable?.enterprise?.month }}</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>5,000,000 views</li>
                                <li>Free installation service</li>
                                <li>SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="payment-plan__item payment-plan__item--popular">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Pro</h3>
                            <p>For growing businesses</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price" *ngIf="paymentPlan">{{ priceTable?.pro?.year }}</span>
                                <span class="payment-plan__body-price" *ngIf="!paymentPlan">{{ priceTable?.pro?.month }}</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>50,000 views</li>
                                <li>Free installation service</li>
                                <li>SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="payment-plan__item">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Basic</h3>
                            <p>Sufficient for startups and new businesses</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price" *ngIf="paymentPlan">{{ priceTable?.basic?.year }}</span>
                                <span class="payment-plan__body-price" *ngIf="!paymentPlan">{{ priceTable?.basic?.month }}</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>5,000 views</li>
                                <li>Free installation service</li>
                                <li>SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li class="payment-plan__item payment-plan__item--last">
                    <div class="payment-plan__item-inner">
                        <div class="payment-plan__header">
                            <h3>Lite</h3>
                            <p>Use it for free and upgrade as you grow</p>
                        </div>
                        <div class="payment-plan__body">
                            <div class="payment-plan__body-inner">
                                <span class="payment-plan__body-currency">€</span>
                                <span class="payment-plan__body-price">0</span>
                                <span class="payment-plan__body-delimiter">/</span>
                                <span class="payment-plan__body-period">Monat</span>
                            </div>
                        </div>
                        <div class="payment-plan__footer">
                            <ul>
                                <li>200 views</li>
                                <li class="remove">Free installation service</li>
                                <li class="remove">SMART WIDGETS logo removal</li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="hero-content hero-content__big">
    <div class="container">
        <div class="row">
            <div class="col-md-8 m-auto">
                <h2 class="h2 h2--white h2__center">Sparen Sie Zeit und Geld mit gebrauchsfertigen Widgets und steigern Sie Ihr Geschäft schneller!</h2>
                <div class="btn-container btn-container--center">
                    <button [routerLink]="['/register']" class="btn btn__dashboard">Kostenlos registrieren</button>
                </div>
            </div>
        </div>
    </div>
</div>