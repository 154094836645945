import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/service/navbar.service';
import { YoutubeService } from 'src/app/service/youtube.service';

@Component({
  selector: 'app-add-youtube',
  templateUrl: './add-youtube.component.html',
  styleUrls: ['./add-youtube.component.less']
})
export class AddYoutubeComponent implements OnInit {
  private routeSubs: Subscription;
  userSubscription: Subscription;
  applicationSelected: any;

  constructor(
    private navService: NavbarService,
    private router: Router,
    private youtubeService: YoutubeService
  ) {
    this.navService.hide();
    this.navService.hideFooter();
    const navigation = this.router.getCurrentNavigation();
    this.applicationSelected = navigation.extras.state ? navigation.extras.state.application : '';
    if (this.applicationSelected === 'Youtube') this.youtubeService.addYoutube();
  }

  ngOnInit(): void {
  }

  addYoutube() {
    this.youtubeService.addYoutube();
  }

  cancel() {
    this.navService.show();
    this.navService.showFooter()
    this.router.navigate(['applications']);
  }

  ngOnDestroy() {
    if (this.routeSubs) this.routeSubs.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }
}
